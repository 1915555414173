import React, { useEffect, useState, useReducer } from 'react';
import {
  Text,
  SafeAreaView,
  TouchableOpacity,
  View,
  FlatList,
  Image,
  AppState,
  TextInput,
  Dimensions,
} from 'react-native';
import { withAppContext } from '../context';
import { chatReducer } from '../redux/chat/reducer';
import Message from '../components/Message';
import ChatHeader from '../components/ChatHeader';
import { useSelector } from 'react-redux';
import { createChannelName } from '../utils';
import { isWeb } from '../utils/isweb';
const { height } = Dimensions.get('window');


const Chat = (props) => {
  const { route, navigation, sendbird } = props;
  const { currentUser, channel } = route.params;
  const profile = useSelector((state) => state.App.profile);

  const [query, setQuery] = useState(null);
  const [state, dispatch] = useReducer(chatReducer, {
    sendbird,
    channel,
    messages: [],
    messageMap: {}, // redId => boolean
    loading: false,
    input: '',
    empty: '',
    error: ''
  });

  // on state change
  useEffect(() => {
    sendbird.addConnectionHandler('chat', connectionHandler);
    sendbird.addChannelHandler('chat', channelHandler);
    AppState.addEventListener('change', handleStateChange);

    if (!sendbird.currentUser) {
      sendbird.connect(currentUser.userId, (err) => {
        if (!err) {
          refresh();
        } else {
          dispatch({
            type: 'error',
            payload: {
              error: 'Connection failed. Please check the network status.'
            }
          });
        }
      });
    } else {
      refresh();
    }

    return () => {
      sendbird.removeConnectionHandler('chat');
      sendbird.removeChannelHandler('chat');
      AppState.removeEventListener('change', handleStateChange);
    };
  }, []);

  // / on query refresh
  useEffect(() => {
    if (query) {
      next(); 
    }
  }, [query]);

  // / on connection event
  const connectionHandler = new sendbird.ConnectionHandler();
  connectionHandler.onReconnectStarted = () => {
    dispatch({
      type: 'error',
      payload: {
        error: 'Connecting..'
      }
    });
  };
  connectionHandler.onReconnectSucceeded = () => {
    dispatch({
      type: 'error',
      payload: {
        error: ''
      }
    });
    refresh();
  };
  connectionHandler.onReconnectFailed = () => {
    dispatch({
      type: 'error',
      payload: {
        error: 'Connection failed. Please check the network status.'
      }
    });
  };

  // / on channel event
  const channelHandler = new sendbird.ChannelHandler();
  channelHandler.onMessageReceived = (targetChannel, message) => {
    if (targetChannel.url === channel.url) {
      dispatch({ type: 'receive-message',
        payload: { message } });
    }
  };
  channelHandler.onMessageUpdated = (targetChannel, message) => {
    if (targetChannel.url === channel.url) {
      dispatch({ type: 'update-message',
        payload: { message } });
    }
  };
  channelHandler.onMessageDeleted = (targetChannel, messageId) => {
    if (targetChannel.url === channel.url) {
      dispatch({ type: 'delete-message',
        payload: { messageId } });
    }
  };
  channelHandler.onUserLeft = (channel, user) => {
    if (user.userId === currentUser.userId) {
      navigation.navigate('Lobby', {
        action: 'leave',
        data: { channel }
      });
    }
  };
  channelHandler.onChannelDeleted = () => {
    navigation.navigate('Lobby', {
      action: 'delete',
      data: { channel }
    });
  };

  const handleStateChange = (newState) => {
    if (newState === 'active') {
      sendbird.setForegroundState();
    } else {
      sendbird.setBackgroundState();
    }
  };
  // const member = () => {
  //   navigation.navigate('Member', { channel,
  //     currentUser });
  // };
  // const leave = () => {
  //   Alert.alert('Leave', 'Are you going to leave this channel?', [
  //     { text: 'Cancel' },
  //     {
  //       text: 'OK',
  //       onPress: () => {
  //         navigation.navigate('Lobby', {
  //           action: 'leave',
  //           data: { channel }
  //         });
  //       }
  //     }
  //   ]);
  // };
  const refresh = () => {
    channel.markAsRead();
    setQuery(channel.createPreviousMessageListQuery());
    dispatch({ type: 'refresh' });
  };
  const next = () => {
    if (query.hasMore) {
      dispatch({ type: 'error',
        payload: { error: '' } });
      query.limit = 50;
      query.reverse = true;
      query.load((err, fetchedMessages) => {
        if (!err) {
          dispatch({ type: 'fetch-messages',
            payload: { messages: fetchedMessages } });
        } else {
          dispatch({ type: 'error',
            payload: { error: 'Failed to get the messages.' } });
        }
      });
    }
  };
  const sendUserMessage = () => {
    if (state.input.length > 0) {
      const params = new sendbird.UserMessageParams();
      params.message = state.input;

      const pendingMessage = channel.sendUserMessage(params, (err, message) => {
        if (!err) {
          dispatch({ type: 'send-message',
            payload: { message } });
        } else {
          setTimeout(() => {
            dispatch({ type: 'error',
              payload: { error: 'Failed to send a message.' } });
            dispatch({ type: 'delete-message',
              payload: { reqId: pendingMessage.reqId } });
          }, 500);
        }
      });
      dispatch({ type: 'send-message',
        payload: { message: pendingMessage,
          clearInput: true } });
    }
  };
  const viewDetail = (message) => {
    if (message.isFileMessage()) {
      // TODO: show file details
    }
  };
  const showContextMenu = (message) => {
    if (message.sender && message.sender.userId === currentUser.userId) {
      // message control
      // showActionSheetWithOptions(
      //   {
      //     title: 'Message control',
      //     message: 'You can edit or delete the message.',
      //     options: ['Edit', 'Delete', 'Cancel'],
      //     cancelButtonIndex: 2,
      //     destructiveButtonIndex: 1
      //   },
      //   buttonIndex => {
      //     switch (buttonIndex) {
      //       case 0: // edit
      //         break;
      //       case 1: // delete
      //         break;
      //       case 2: // cancel
      //         break;
      //     }
      //   }
      // );
    }
  };
  const name = createChannelName(channel);

  return (
    <SafeAreaView style={style.container}>
      <View style={{
        width: isWeb() ? 500 : null
      }}>
        <ChatHeader name={name} isAdmin={profile.is_superuser} small={true} navigation={navigation}/>

        <FlatList
          data={state.messages}
          inverted={true}
          renderItem={({ item }) => (
            <Message
              key={item.reqId}
              channel={channel}
              message={item}
              onPress={(message) => viewDetail(message)}
              onLongPress={(message) => showContextMenu(message)}
            />
          )}
          keyExtractor={(item) => `${item.messageId}` || item.reqId}
          contentContainerStyle={{ flexGrow: 1,
            paddingVertical: 10 }}
          ListHeaderComponent={
            state.error && (
              <View style={style.errorContainer}>
                <Text style={style.error}>{state.error}</Text>
              </View>
            )
          }
          ListEmptyComponent={
            <View style={style.emptyContainer}>
              <Text style={style.empty}>{state.empty}</Text>
            </View>
          }
          onEndReached={() => next()}
          onEndReachedThreshold={0.5}
        />
        <View style={style.inputContainer}>
          <TextInput
            value={state.input}
            style={style.input}
            multiline={true}
            placeholder="Type your message here..."
            numberOfLines={2}
            onChangeText={(content) => {
              if (content.length > 0) {
                channel.startTyping();
              } else {
                channel.endTyping();
              }
              dispatch({ type: 'typing',
                payload: { input: content } });
            }}
          />
          <TouchableOpacity activeOpacity={0.85} style={style.sendButton} onPress={sendUserMessage}>
            <Image style={{ width: 40,
              height: 40 }} source={require('../assets/sendMessage.png')} />   
            {/* <Icon name="send" color={state.input.length > 0 ? '#0069d8' : '#ddd'} size={28} /> */}
          </TouchableOpacity>
        </View>
      </View>
    </SafeAreaView>
  );
};

const style = {
  container: {
    flex: isWeb() ? null : 1,
    alignItems: isWeb() ? 'center' : null,
    height: isWeb() ? height : null
  },
  headerRightContainer: {
    flexDirection: 'row'
  },
  headerRightButton: {
    marginRight: 10
  },
  errorContainer: {
    backgroundColor: '#333',
    opacity: 0.8,
    padding: 10
  },
  error: {
    color: '#fff'
  },
  emptyContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  empty: {
    fontSize: 24,
    color: '#999',
    alignSelf: 'center'
  },
  inputContainer: {
    flexDirection: 'row',
    backgroundColor: '#e0e1e2',
    paddingVertical: 4,
    paddingHorizontal: 10,
    alignItems: 'center'
  },
  input: {
    flex: 1,
    fontSize: 20,
    color: '#555'
  },
  uploadButton: {
    marginRight: 10
  },
  sendButton: {
    marginLeft: 10
  }
};

export default withAppContext(Chat);