import React from 'react'
import { View, StyleSheet, Image, Text } from 'react-native'
import { useDispatch } from 'react-redux'
import { logout } from '../redux/auth/actions'
import { colors } from '../utils/colors'
import Button from './Button'

const WebHeader = ({ navigation }) => {
  const dispatch = useDispatch()
  const onLogout = () => {
    dispatch(logout())
  }
  return (
    <>
      <View style={styles.body}>
        <View style={{ alignItems: 'center' }} >
          <Image style={styles.icon} source={require('../assets/logoBlue.png')} />
          <Text style={styles.text}>DISCLOSURE</Text>
          <Text style={styles.text2}>LEGAL APP</Text>
        </View>
        <View style={{ flexDirection: 'row' }}>
          <Button onPress={() => navigation.navigate('HomeDashboard')} name="Dashboard" color={colors.blue}/>
          <Button onPress={() => navigation.navigate('Profile')} name="Profile" color={colors.blue}/>
          <Button name="Help" color={colors.blue}/>
          <Button onPress={() => onLogout()} name="Logout" backgroundColor={colors.blue} color={colors.white}/>
        </View>
      </View>
    </>
  ) 
}
const styles = StyleSheet.create({
  text2: {
    textAlign: 'center',
    fontSize: 12,
    color: colors.blue,
    letterSpacing: 4,
    fontWeight: 'bold'
  },
  text: {
    fontSize: 14,
    textAlign: 'center',
    color: colors.blue,
    letterSpacing: 4,
    fontWeight: 'bold'
  },
  icon: {
    width: 70,
    height: 70,
    resizeMode: 'contain'
  },
  body: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '90%'
  }
})
export default WebHeader;