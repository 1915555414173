import React from 'react';
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  Modal
} from 'react-native';
import { colors } from '../utils/colors';
import { isWeb } from '../utils/isweb';
import Input from './Input';

const MetricModal = ({ onCancel, onSave, isModalVisible, title, value, onChangeText, rightButton, content, hideInput }) => (
  <Modal transparent={true} visible={isModalVisible} animationType="fade">
    <View style={styles.modal}>
      <View style={styles.modalContainer} >
        <View style={{ padding: 20,
          width: '100%' }}>
          <Text style={styles.modalTitle}>{title}</Text>
          {
            hideInput === true ? <Text style={styles.content} >{content}</Text> : <Input value={value} onChangeText={onChangeText} placeholder="Enter your input" />
          }
        </View>
        <View style={styles.containerButton}> 
          <TouchableOpacity onPress={() => onCancel()} style={[styles.button, { borderRightWidth: 1, }]} ><Text style={styles.buttonText}>Discard Changes</Text></TouchableOpacity>
          <TouchableOpacity onPress={() => onSave()} style={styles.button}><Text style={[styles.buttonText, { color: colors.blue }]}>{ rightButton }</Text></TouchableOpacity>
        </View>
      </View>
    </View>
  </Modal>
)
const styles = StyleSheet.create({
  buttonText: {
    color: colors.blue,
    fontWeight: 'bold',
    fontSize: 16,
    textAlign: 'center',
    padding: 10
  },  
  button: {
    padding: 10,
    borderColor: colors.border,
    flex: 1,
    fontSize: 12
  },
  containerButton: {
    borderTopWidth: 1,
    borderColor: colors.border,
    // flexWrap: 'wrap',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between'    
  },
  modalInput: {
    color: colors.white
  },
  modalTitle: { 
    color: colors.black,
    fontWeight: 'bold',
    fontSize: 16, 
    paddingTop: 10,
    textAlign: 'center'
  },
  modalContainer: { 
    backgroundColor: colors.white,
    alignItems: 'center',
    borderRadius: 15,
  },
  modal: { 
    padding: 15,
    backgroundColor: 'rgba(0,0,0,0.7)',
    flex: 1,
    justifyContent: 'center',
    alignItems: isWeb() ? 'center' : null,
  },
  content: {
    color: colors.textColor,
    marginTop: 20,
    marginBottom: 5,
    textAlign: 'center',
    fontSize: 16
  }
});

export default MetricModal;