
import * as React from 'react';
import {  
  View,
  Text,
  StyleSheet,
  useWindowDimensions,
  TouchableOpacity,
  ScrollView,
  Alert,
  SafeAreaView,
} from 'react-native';

import { TabView, SceneMap, TabBar } from 'react-native-tab-view';
import { colors } from '../utils/colors'
import Logo from '../components/Logo'
import Input from '../components/Input';
import Button from '../components/Button';
import SocialLogin from '../components/SocialLogin';
import { useDispatch } from 'react-redux';
import { login } from '../redux/auth/actions';
import { isWeb } from '../utils/isweb';
// import SocialLoginWeb from '../components/SocialLogin.web';

const Form = (props) => {
  const { navigation, userType, item, changed, setChanged } = props;
  const dispatch = useDispatch()
  const [values, setValues] = React.useState({});

  React.useEffect(() => {
    if (item !== userType && !changed) {
      props.jumpTo(item)
      setChanged(true)
    }
  }, [item])

  const onSubmit = () => {
    if (!values.email || !values.password) {
      Alert.alert('Please enter all fields')
    } else {
      dispatch(login(values))
    }
  }

  const onChange = (key, value) => {
    setValues({
      ...values,
      [key]: value
    })
  }

  return (
    <ScrollView>
      <View style={styles.body}>
        <View style={styles.firstContainer} >
          <Input onChangeText={(v) => onChange('email', v)} value={values.email} title="Email" placeholder="Your email id " />
          <Input onChangeText={(v) => onChange('password', v)} value={values.password} title="Password" placeholder="Password" password={true} />
          <View style={styles.containerForget}>
            <View/>
            <TouchableOpacity styles={styles.forgetButton} onPress={() => navigation.navigate('ForgotPassword')}>
              <Text style={styles.forgetText}> Forgot password?</Text>
            </TouchableOpacity>
          </View>
          <Button onPress={() => onSubmit()} name="Login" backgroundColor={colors.blue} color={colors.white}/>
          <View style={styles.signupContainer} >
            <Text style={styles.textContainer}>Don’t have an account? </Text>
            <TouchableOpacity onPress={() => navigation.navigate('SignUp', { item: userType })}>
              <Text style={styles.textContainerSignUp}> Sign up</Text>
            </TouchableOpacity>
          </View>
          {
            isWeb() ? <View style={styles.loginWitchContainer}>
              <Text style={styles.loginWitch}>Or login with</Text>
              <SocialLogin userType={userType} />
            </View> : <View style={styles.loginWitchContainer}>
              <Text style={styles.loginWitch}>Or login with</Text>
              <SocialLogin userType={userType} />
            </View>
          }
        </View>
      </View>
    </ScrollView>
  ) 
};

const Login = ({ navigation, route }) => {
  const { item } = route.params;
  const layout = useWindowDimensions();
  const [changed, setChanged] = React.useState(false)
  const [index, setIndex] = React.useState(1);
  
  const [routes] = React.useState([
    { key: 'lawyer',
      title: 'I’m a Lawyer' },
    { key: 'customer',
      title: 'I’m a Customer' },
  ]);
  
  const renderScene = SceneMap({
    lawyer: (props) => <Form navigation={navigation} item={item} changed={changed} setChanged={setChanged} userType="lawyer" {...props} />,
    customer: (props) => <Form navigation={navigation} item={item} changed={changed} setChanged={setChanged} userType="customer" {...props} />,
  });

  const renderTabBar = (props) => (
    <View style={{
      alignItems: isWeb() ? 'center' : null
    }}>
      <TabBar
        {...props}
        indicatorStyle={{ backgroundColor: colors.blue }}
        style={{
          backgroundColor: colors.whiteBackground,
          color: colors.blue,
          width: isWeb() ? 500 : null,
        
        }}
        getLabelText={({ route }) => route.title}
        renderLabel={({ route }) => (
          <View>
            <Text style={{ fontSize: 16,
              fontWeight: 'bold',
              textAlign: 'center',
              color: route.key === props.navigationState.routes[props.navigationState.index].key
                ? colors.black : colors.textColor }}>
              {route.title}
            </Text>
          </View>
        )}
      />
    </View>
  );
  
  return (
    <SafeAreaView style={{
      flex: 1,
      backgroundColor: colors.whiteBackground,
    }}>
      <View style={styles.logoContainer}>
        <Logo 
          navigation={navigation}
          imageLogo={require('../assets/logoBlue.png')} 
          color={colors.blue}
        />
      </View>
      <TabView
        renderTabBar={renderTabBar}
        navigationState={{ index,
          routes }}
        renderScene={renderScene}
        onIndexChange={setIndex}
        initialLayout={{ width: layout.width }}
        style={{ backgroundColor: colors.whiteBackground }}
      />
    </SafeAreaView>
  );
}
const styles = StyleSheet.create({
  loginWitch: {
    fontSize: 15,
    fontWeight: 'bold'
  },
  loginWitchContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 20
  },
  textContainerSignUp: {
    fontSize: 15,
    color: colors.blue,
    fontWeight: 'bold',
    textDecorationLine: 'underline'
  },
  textContainer: {
    fontWeight: 'bold',
    fontSize: 15
  },
  signupContainer: {
    marginTop: 15,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },  
  forgetText: { 
    marginHorizontal: 20,
    fontSize: 18,
    color: colors.blue,
    fontWeight: 'bold',
    textDecorationLine: 'underline'
  },
  containerForget: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    marginBottom: 20
  },
  firstContainer: {
    paddingTop: 20,
    paddingBottom: 20,
    width: isWeb() ? 500 : null,
  },
  logoContainer: {
    backgroundColor: colors.whiteBackground,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 25,
  },
  body: {
    backgroundColor: colors.backgroundColor,
    alignItems: isWeb() ? 'center' : null,
  }
})
export default Login;