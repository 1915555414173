export const APP_CHANGE_LOADING_STATE = 'APP/CHANGE_LOADING_STATE';
export const APP_CLEAR_STATE = 'APP/CLEAR_STATE';

export const APP_GET_PROFILE_REQUEST = 'APP/GET_PROFILE/REQUEST';
export const APP_GET_PROFILE_SUCCESS = 'APP/GET_PROFILE/SUCCESS';
export const APP_GET_PROFILE_ERROR = 'APP/GET_PROFILE/ERROR';

export const APP_UPDATE_PROFILE_REQUEST = 'APP/UPDATE_PROFILE/REQUEST';
export const APP_UPDATE_PROFILE_SUCCESS = 'APP/UPDATE_PROFILE/SUCCESS';
export const APP_UPDATE_PROFILE_ERROR = 'APP/UPDATE_PROFILE/ERROR';

export const APP_ADD_CARD_REQUEST = 'APP/ADD_CARD/REQUEST';
export const APP_ADD_CARD_SUCCESS = 'APP/ADD_CARD/SUCCESS';
export const APP_ADD_CARD_ERROR = 'APP/ADD_CARD/ERROR';

export const APP_GET_SUBSCRIPE_FEES_REQUEST = 'APP/GET_SUBSCRIPE_FEES/REQUEST';
export const APP_GET_SUBSCRIPE_FEES_SUCCESS = 'APP/GET_SUBSCRIPE_FEES/SUCCESS';
export const APP_GET_SUBSCRIPE_FEES_ERROR = 'APP/GET_SUBSCRIPE_FEES/ERROR';

export const APP_CREAT_SUBSCRIPTION_REQUEST = 'APP/CREAT_SUBSCRIPTION/REQUEST';
export const APP_CREAT_SUBSCRIPTION_SUCCESS = 'APP/CREAT_SUBSCRIPTION/SUCCESS';
export const APP_CREAT_SUBSCRIPTION_ERROR = 'APP/CREAT_SUBSCRIPTION/ERROR';

export const APP_USER_FILES_REQUEST = 'APP/USER_FILES/REQUEST';
export const APP_USER_FILES_SUCCESS = 'APP/USER_FILES/SUCCESS';
export const APP_USER_FILES_ERROR = 'APP/USER_FILES/ERROR';

export const APP_GET_FILES_REQUEST = 'APP/GET_FILES/REQUEST';
export const APP_GET_FILES_SUCCESS = 'APP/GET_FILES/SUCCESS';
export const APP_GET_FILES_ERROR = 'APP/GET_FILES/ERROR';

export const APP_GET_EMAILS_REQUEST = 'APP/GET_EMAILS/REQUEST';
export const APP_GET_EMAILS_SUCCESS = 'APP/GET_EMAILS/SUCCESS';
export const APP_GET_EMAILS_ERROR = 'APP/GET_EMAILS/ERROR';

export const APP_SHARE_EMAILS_REQUEST = 'APP/SHARE_EMAILS/REQUEST';
export const APP_SHARE_EMAILS_SUCCESS = 'APP/SHARE_EMAILS/SUCCESS';
export const APP_SHARE_EMAILS_ERROR = 'APP/SHARE_EMAILS/ERROR';

export const APP_GET_SHARED_EMAILS_REQUEST = 'APP/GET_SHARED_EMAILS/REQUEST';
export const APP_GET_SHARED_EMAILS_SUCCESS = 'APP/GET_SHARED_EMAILS/SUCCESS';
export const APP_GET_SHARED_EMAILS_ERROR = 'APP/GET_SHARED_EMAILS/ERROR';

export const APP_GET_LAWYER_CLIENTS_REQUEST = 'APP/GET_LAWYER_CLIENTS/REQUEST';
export const APP_GET_LAWYER_CLIENTS_SUCCESS = 'APP/GET_LAWYER_CLIENTS/SUCCESS';
export const APP_GET_LAWYER_CLIENTS_ERROR = 'APP/GET_LAWYER_CLIENTS/ERROR';

export const APP_DELETE_FILES_REQUEST = 'APP/DELETE_FILES/REQUEST';
export const APP_DELETE_FILES_SUCCESS = 'APP/DELETE_FILES/SUCCESS';
export const APP_DELETE_FILES_ERROR = 'APP/DELETE_FILES/ERROR';

export const APP_DELETE_EMAIL_REQUEST = 'APP/DELETE_EMAIL/REQUEST';
export const APP_DELETE_EMAIL_SUCCESS = 'APP/DELETE_EMAIL/SUCCESS';
export const APP_DELETE_EMAIL_ERROR = 'APP/DELETE_EMAIL/ERROR';

export const APP_DETAILS_FILES_REQUEST = 'APP/DETAILS_FILES/REQUEST';
export const APP_DETAILS_FILES_SUCCESS = 'APP/DETAILS_FILES/SUCCESS';
export const APP_DETAILS_FILES_ERROR = 'APP/DETAILS_FILES/ERROR';

export const APP_INVITE_REQUEST = 'APP/INVITE/REQUEST';
export const APP_INVITE_SUCCESS = 'APP/INVITE/SUCCESS';
export const APP_INVITE_ERROR = 'APP/INVITE/ERROR';

export const APP_INVITE_LAWYER_REQUEST = 'APP/INVITE_LAWYER/REQUEST';
export const APP_INVITE_LAWYER_SUCCESS = 'APP/INVITE_LAWYER/SUCCESS';
export const APP_INVITE_LAWYER_ERROR = 'APP/INVITE_LAWYER/ERROR';

export const APP_GET_APPLICATIONS_REQUEST = 'APP/GET_APPLICATIONS/REQUEST';
export const APP_GET_APPLICATIONS_SUCCESS = 'APP/GET_APPLICATIONS/SUCCESS';
export const APP_GET_APPLICATIONS_ERROR = 'APP/GET_APPLICATIONS/ERROR';

export const APP_GET_SEARCH_REQUEST = 'APP/GET_SEARCH/REQUEST';
export const APP_GET_SEARCH_SUCCESS = 'APP/GET_SEARCH/SUCCESS';
export const APP_GET_SEARCH_ERROR = 'APP/GET_SEARCH/ERROR';