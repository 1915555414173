import React, { useEffect, useState } from 'react'
import {
  View,
  StyleSheet,
  Text,
  Image,
  TouchableOpacity,
  Dimensions,
  SafeAreaView,
  FlatList
} from 'react-native'
import { colors } from '../utils/colors'
import { useDispatch, useSelector } from 'react-redux'
import { emailDelete, getUserEmails, getUserFiles, shareEmail } from '../redux/app/actions'
import Header from '../components/Header'
import { isWeb } from '../utils/isweb'
import { BASE_URL } from '../utils/http'
import WebHeader from '../components/WebHeader'
import InputSearch from '../components/InputSearch'

const { height } = Dimensions.get('window')
const Emails = ({ navigation }) => {
  const [isLoading, setLoading] = useState(true)
  const profile = useSelector((state) => state.App.profile)
  const [data, setData] = useState([])
  const [show, setShow] = useState(0)
  const dispatch = useDispatch()
  const [placehold] = useState('Search...')
  const [visible, setVisible] = useState(false)
  const [fullData, setFullData] = useState([])
  const [modal, setModal] = useState(0)
  const [share, setShare] = useState(0)
  
  const onModal = (id) => {
    setModal(id)
  }

  const onShare = (id) => {
    setShare(id)
  }

  const onEmail = (id) => {
    dispatch(shareEmail(id))
    dispatch(getUserEmails(profile.id))
    console.log(id)
  }

  const onDelete = (id) => {
    dispatch(emailDelete(id))
    dispatch(getUserEmails(profile.id))
    console.log(id)
  }

  useEffect(() => {
    dispatch(getUserEmails(profile.id))
    dispatch(getUserFiles())
  }, [])


  useEffect(() => {
    fetch(`${BASE_URL}/api/v1/accounts/email/${profile.id}/`)
      .then((response) => response.json())
      .then((json) => {
        setData(json)
        setFullData(json)
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false))
  }, [])

  const searchFilterFunction = (text) => {
    const newData = fullData.filter((item) => {
      const itemData = `${item.subject.toUpperCase()}   
        ${item.sender.toUpperCase()} ${item.body.message}`
      const textData = text.toUpperCase()

      return itemData.indexOf(textData) > -1
    })
    setData(newData)
  }

  return (
    // <ScrollView>
    <SafeAreaView>
      <View style={styles.body}>
        {isWeb() ? (
          <>
            <WebHeader navigation={navigation} />
            <Header
              width="50%"
              onPress={() => navigation.goBack('')}
              title="Email List"
            />
          </>
        ) : (
          <Header onPress={() => navigation.goBack('')} title="Email List" />
        )}
        {data ? (
          <View style={{ flex: 1,
            padding: 24 }}>
            {isLoading ? (
              <Text>Loading...</Text>
            ) : (
              <View>
                <Text
                  style={{ fontSize: 18,
                    color: 'green',
                    textAlign: 'center' }}
                >
                  {data.subject}
                </Text>
                <Text
                  style={{
                    fontSize: 20,
                    color: '#000',
                    textAlign: 'center',
                    paddingBottom: 20
                  }}
                >
                  My Email Inbox
                </Text>
                <InputSearch
                  placeholder={placehold}
                  search={require('../assets/search.png')}
                  filter={
                    visible
                      ? require('../assets/filter2.png')
                      : require('../assets/filter.png')
                  }
                  onPress={() => setVisible(!visible)}
                  visible={visible}
                  onChangeText={(v) => searchFilterFunction(v)}
                  // value={query}
                />

                <FlatList
                  style={{ marginTop: 20 }}
                  data={data}
                  keyExtractor={({ id }) => id}
                  // inverted={true}
                  renderItem={({ item }) => (
                    <View>
                      <TouchableOpacity
                        onPress={() => {
                          setShow(item.id)
                        }}
                      >
                        <View style={{ flexDirection: 'row' }}>
                          <Text style={{ color: colors.blue,
                            fontSize: 16, 
                            width: '92%' }}>
                            {item.subject}
                          </Text>
                          <TouchableOpacity onPress={() => onModal(item.id)}>
                            <Image resizeMode="contain" style={styles.icon} source={require('../assets/trash.png')} />
                            {/* <Text style={{ color: colors.blue,
                              fontWeight: 'bold' }}>Delet</Text> */}
                          </TouchableOpacity>
                        </View>
                        <View style={{ flexDirection: 'row' }}>
                          <Text
                            style={{
                              color: 'grey',
                              paddingBottom: 35,
                              width: '92%',
                              fontSize: 12
                            }}
                          >
                            {item.sender}
                          </Text>
                          <TouchableOpacity onPress={() => onShare(item.id)}>
                            <Image resizeMode="contain" style={styles.icon} source={require('../assets/share.png')} />
                          </TouchableOpacity>
                        </View>
                      </TouchableOpacity>
                      {show !== item.id ? null : (
                        <Text style={{ paddingBottom: 20 }}>
                          {item.body.message}
                        </Text>
                      )}
                      {modal === item.id
                        ? <>
                          <View style={{
                            flexDirection: 'row',
                            justifyContent: 'center'
                          }}>
                            <TouchableOpacity style={styles.button} onPress={() => onDelete(item.id)}>
                              <Text style={styles.buttonTextDelete}>Yes delete</Text>
                            </TouchableOpacity>
                            <TouchableOpacity style={styles.button} onPress={() => setModal(0)}>
                              <Text style={styles.buttonText}>Cancel</Text>
                            </TouchableOpacity>
                          </View>
                        </>
                        : null}

                      {share === item.id
                        ? <>
                          <View style={{
                            flexDirection: 'row',
                            justifyContent: 'center'
                          }}>
                            <TouchableOpacity style={styles.button} onPress={() => onEmail(item.id)}
                            >
                              <Text style={styles.buttonTextShare}>Share email</Text>
                            </TouchableOpacity>
                            <TouchableOpacity style={styles.button} onPress={() => setShare(0)}>
                              <Text style={styles.buttonText}>Cancel</Text>
                            </TouchableOpacity>
                          </View>
                        </>
                        : null}
                    </View>
                  )}
                />
              </View>
            )}
          </View>
        ) : (
          <Text style={{ marginVertical: 50 }}>
            This section is empty, please add emails.
          </Text>
        )}
      </View>
    </SafeAreaView>
    // </ScrollView>
  )
}
const styles = StyleSheet.create({
  buttonTextDelete: {
    color: 'red',
    fontWeight: 'bold'
  },
  buttonTextShare: {
    color: 'green',
    fontWeight: 'bold'
  },
  buttonText: {
    color: colors.blue,
    fontWeight: 'bold'
  },
  button: {
    paddingHorizontal: 25,
    paddingVertical: 15
  },
  detailsCont: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  details: {
    padding: 20
  },
  type: {
    fontSize: 12,
    color: colors.textColor,
    fontWeight: '400'
  },
  icon: {
    width: 25,
    height: 25,
  },
  filterBar: {
    paddingHorizontal: 20,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  searchContainer: {
    padding: 15,
    marginTop: 20,
    backgroundColor: colors.white,
    width: isWeb() ? '50%' : '95%',
    borderRadius: 10
  },
  text: {
    fontSize: 20,
    margin: 22,
    color: colors.white,
    letterSpacing: 12,
    fontWeight: 'bold'
  },
  body: {
    paddingBottom: 10,
    padding: 15,
    alignItems: 'center',
    height
  }
})
export default Emails