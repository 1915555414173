import React from 'react';
import {
  StyleSheet,
  View,
  Text,
} from 'react-native';
import RNPickerSelect from 'react-native-picker-select';
import { colors } from '../utils/colors';

const Picker = ({ title, value, onValueChange, options, backColor }) => (
  <View style={styles.body}>  
    { 
      !title ? null : <Text style={styles.inputTitle}>{title}</Text>
    }
    <View style={[styles.input, { backgroundColor: backColor }]}>
      <RNPickerSelect
        value={value}
        style={pickerSelectStyles}
        placeholder="Select..."
        onValueChange={onValueChange}
        items={options}
      />
    </View>
  </View>
)
const styles = StyleSheet.create({
  input: {
    borderRadius: 5,
  }
});
const pickerSelectStyles = StyleSheet.create({
  inputIOS: {
    color: colors.black,
  },
  inputAndroid: {
    color: colors.black,
  },
});
export default Picker;