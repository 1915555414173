import React, { useEffect, useState } from 'react'
import { 
  View,
  StyleSheet,
  Image,
  ScrollView,
  Dimensions,
  SafeAreaView,
} from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import Button from '../components/Button'
import Header from '../components/Header'
import Input from '../components/Input'
import MetricModal from '../components/Modal'
import { logout } from '../redux/auth/actions'
import { colors } from '../utils/colors'
import ImageUploader from '../components/ImageUploader'
import { updateProfile } from '../redux/app/actions'
import { isWeb } from '../utils/isweb'
import WebHeader from '../components/WebHeader'
const { height } = Dimensions.get('window');


const Profile = ({ navigation }) => { 
  const profile = useSelector((state) => state.App.profile);
  const token = useSelector((state) => state.Auth.accessToken);
  const [isModalVisible, setModalVisible] = useState(false);
  const dispatch = useDispatch()
  const [image, setImage] = useState(profile?.profile_picture)
  const [values, setValues] = useState({});
  
  useEffect(() => {
    setValues({
      id: profile?.id,
      email: profile?.email,
      name: profile?.first_name,
      surname: profile?.last_name,
      phone: profile?.phone,
      location: profile?.profile?.location
    })
  }, [profile])

  const onSave = () => {
    setModalVisible(false)
    
    const d = {
      first_name: values.name,
      last_name: values.surname,
      email: values.email,
      phone: values.phone,
      'profile.location': values.location
    }
    if (image?.uri) {
      d.profile_picture = { name: image.fileName,
        type: image.type,
        uri: image.uri }
    }
    // check image
    dispatch(updateProfile(d, token))
  }

  const onOpen = () => {
    setModalVisible(true);
  }

  const onChange = (key, value) => {
    setValues({
      ...values,
      [key]: value
    })
  }
  
  const onLogout = () => {
    dispatch(logout())
  }

  const onCancel = () => {
    setModalVisible(false);
  }

  return (
    <SafeAreaView>
      <ScrollView>
        <MetricModal
          onSave={onSave}
          onCancel={onCancel}
          isModalVisible={isModalVisible}
          title="Alert"
          content="You want to update the changes on your profile or discard them?"
          hideInput={true}
          rightButton="Save Changes"
        />
        <View style={styles.body}>
          {
            isWeb()
              ? <WebHeader navigation={navigation} />
              : <Header onPress={() => navigation.goBack('')} title="User Profile" />
          }
          <View style={styles.imageContainer}>
            <ImageUploader onUpload={setImage} values={isWeb() ? values : null}>
              <Image
                style={styles.icon}
                resizeMode="cover"
                source={{ uri: image?.uri || image }
                  // require('../assets/avatar.png')
                }
              />
            </ImageUploader>
          </View>
          <View style={styles.formContainer}>
            <Input onChangeText={(v) => onChange('email', v)} value={values.email} title="Email" placeholder="Your email ID" />
            <Input onChangeText={(v) => onChange('name', v)} value={values.name} title="First Name" placeholder="First name" />
            <Input onChangeText={(v) => onChange('surname', v)} value={values.surname} title="Last Name" placeholder="Last name" />
            <Input onChangeText={(v) => onChange('phone', v)} value={values.phone} title="Phone" placeholder="+12025550136" />
            <Input onChangeText={(v) => onChange('location', v)} value={values.location} title="Location" placeholder="Where are you?" />
            <Button onPress={onOpen} name="Update profile" backgroundColor={colors.blue} color={colors.white} />
            { profile.user_type === 'lawyer' ? <Button onPress={() => navigation.navigate('AddNewCard')} name="Add card" backgroundColor={colors.blue} color={colors.white} /> : null }
            <Button onPress={() => onLogout()} name="Logout" backgroundColor={colors.cancelColor} color={colors.blue} />
          </View>
        </View>
      </ScrollView>
    </SafeAreaView>
  ) 
}
const styles = StyleSheet.create({
  imageContainer: {
    marginTop: 30,
    alignItems: 'center',
  },
  icon: {
    backgroundColor: '#cccccc',
    width: 130,
    height: 130,
    borderRadius: 150
  },
  body: {
    padding: 10,
    justifyContent: isWeb() ? null : 'center',
    alignItems: 'center',
    height: isWeb() ? height : null
  }
})

export default Profile;