/* eslint-disable no-lone-blocks */
import React, { useEffect, useRef } from 'react'
import { GoogleLogin } from 'react-google-login';
import { facebookLogin, googleLogin } from '../redux/auth/actions';
import FacebookLogin from 'react-facebook-login';
import { useDispatch } from 'react-redux';
// import jwt_decode from 'jwt-decode'
import { gapi } from 'gapi-script'
import { StyleSheet, TouchableOpacity, View } from 'react-native';

const SocialLoginWeb = ({ userType }) => {
  const dispatch = useDispatch()
  console.log(userType)
  const googleButton = useRef(null);

  const loadScript = (src) => new Promise((resolve, reject) => {
    if (document.querySelector(`script[src="${src}"]`)) {
      // return resolve()
    }
    const script = document.createElement('script')
    script.src = src
    script.onload = () => resolve()
    script.onerror = (err) => reject(err)
    document.body.appendChild(script)
  })

  // useEffect(() => {
  // /* global google */
  // google.accounts.id.initialize({
  //  client_id: '574925187861-hsgurbtr35k3r21uhavsi1pkpesgp0vh.apps.googleusercontent.com',
  //   callback: responseGoogle
  //  })

  //  google.accounts.id.renderButton(s
  //    document.getElementById('signInDiv'),
  //    { theme: 'outline', 
  //      size: 'large' }
  //  )

  // }, [])

  useEffect(() => {
    const src = 'https://accounts.google.com/gsi/client'
    const id = '574925187861-hsgurbtr35k3r21uhavsi1pkpesgp0vh.apps.googleusercontent.com'

    function start () {
      gapi.client.init({
        clientId: id,
        scope: ''
      })
    }
    gapi.load('client:auth2', start)

    loadScript(src)
      .then(() => {
        /* global google */
        console.log(google)
        google.accounts.id.initialize({
          client_id: id,
          callback: responseGoogle,
        })
        google.accounts.id.renderButton(
          googleButton.current, 
          { theme: 'outline', 
            size: 'large' } 
        )
      })
      .catch(console.error)

    return () => {
      const scriptTag = document.querySelector(`script[src="${src}"]`)
      if (scriptTag) { 
        // document.body.removeChild(scriptTag)
      }
    }

  }, [])

  // console.log(google.accounts.id.initialize)


  const responseGoogle = (response) => {
    // const userObject = jwt_decode(response.credential)
    dispatch(googleLogin({
      access_token: response.accessToken,
      user_type: userType,
    }));
    // console.log(response)
  }


  const responseFacebook = (response) => {
    // console.log(response.accessToken);
    dispatch(facebookLogin({
      access_token: response.accessToken,
      user_type: userType,
    }));
  } 

  return (
    <View style={styles.body}>

      <GoogleLogin
        clientId="574925187861-hsgurbtr35k3r21uhavsi1pkpesgp0vh.apps.googleusercontent.com"
        buttonText="Login with Google"
        onSuccess={responseGoogle}
        onFailure={responseGoogle}
        cookiePolicy={'single_host_origin'}
      />
      
      <TouchableOpacity>
        <FacebookLogin
          appId="919949215257914"
          autoLoad={false}
          fields="name,email,picture"
          callback={responseFacebook}
          cssClass="my-facebook-button-class"
          icon="fa-facebook"
          buttonStyle={{ color: '#fff', 
            backgroundColor: '#385499',
            borderColor: 'transparent',
            fontSize: 14,
            height: 43,
            marginLeft: 20,
            borderRadius: 2 }}
        />
      </TouchableOpacity>
    </View>
  ) 
}

const styles = StyleSheet.create({
  icon: {
    marginHorizontal: 15,
    width: 35,
    height: 35
  },
  body: {
    margin: 25,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
}) 
export default SocialLoginWeb;