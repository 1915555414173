import React, { useState } from 'react';
import {  
  View,
  StyleSheet,
  ScrollView,
  Text,
  SafeAreaView,
} from 'react-native';
import { colors } from '../utils/colors'
import Input from '../components/Input';
import Button from '../components/Button';
import Header from '../components/Header';
import Picker from '../components/Picker';
import { useDispatch } from 'react-redux';
import { addCard } from '../redux/app/actions';
import countries from '../utils/countires.json';
import states from '../utils/states.json';
import { isWeb } from '../utils/isweb';

const AddNewCard = ({ navigation }) => {
  // const addCardList = useSelector((state) => state.App.addCardList);
  const [values, setValues] = useState({});
  const dispatch = useDispatch()

  const onSubmit = () => {
    dispatch(addCard(values, navigation))
  }

  const onChange = (key, value) => {
    setValues({
      ...values,
      [key]: value
    })
  }
  return (
    <SafeAreaView>
      <ScrollView>
        <View style={styles.body}>
          <View style={styles.container}>
            <Header back title="Add New Card"/>
            <Input onChangeText={(v) => onChange('first_name', v)} value={values.first_name} title="First name" placeholder="Type your first name" />
            <Input onChangeText={(v) => onChange('last_name', v)} value={values.last_name} title="Last name" placeholder="Type your last name" />
            <Input onChangeText={(v) => onChange('address_line1', v)} value={values.address_line1} title="Address line 1" placeholder="Type your address" />
            <Input onChangeText={(v) => onChange('address_line2', v)} value={values.address_line2} title="Address line 2" placeholder="Type your address" />
            <View style={styles.card}>
              <Input onChangeText={(v) => onChange('address_city', v)} value={values.address_city} width={'57%'} title="City" placeholder="Type your city" />
              <View style={styles.pickerCountry}>
                <Text style={styles.title}>State</Text>
                <Picker options={states} value={values.address_state} onValueChange={(v) => onChange('address_state', v)} backColor={colors.white} />
                <Text style={{ width: '100%',
                  height: 60,
                  position: 'absolute',
                  bottom: 0,
                  left: 0 }}>{' '}
                </Text>
              </View>
            </View>
            <Input onChangeText={(v) => onChange('address_zip', v)} value={values.address_zip} title="Zip" placeholder="Type your zip code" />
            <View style={styles.pickerCountry}>
              <Text style={styles.title}>Country</Text>
              <Picker options={countries} value={values.address_country} onValueChange={(v) => onChange('address_country', v)} backColor={colors.white} />
              <Text style={{ width: '100%',
                height: 60,
                position: 'absolute',
                bottom: 0,
                left: 0 }}>{' '}
              </Text>
            </View>
            <Input keyboardType ="numeric" onChangeText={(v) => onChange('number', v)} value={values.number} title="Card number" placeholder="0000 0000 0000 0000" />
            <View style={styles.card}>
              <Input keyboardType ="numeric" onChangeText={(v) => onChange('exp_month', v)} value={values.exp_month} width={'45%'} title="Card expiration month" placeholder="10" />
              <Input keyboardType ="numeric" onChangeText={(v) => onChange('exp_year', v)} value={values.exp_year} width={'45%'} title="Card expiration year" placeholder="2022" />
            </View>
            <Input keyboardType ="numeric" onChangeText={(v) => onChange('cvc', v)} value={values.cvc} width={'40%'} title="CVV" placeholder="354" />
            <View style={styles.button}>
              <Button onPress={() => onSubmit()} name="Update payment method" backgroundColor={colors.blue} color={colors.white}/>
              <Button onPress={() => navigation.navigate('HomeDashboard')} name="Cancel" backgroundColor={colors.cancelColor} color={colors.blue}/>
            </View>
          </View>
        </View>
      </ScrollView>
    </SafeAreaView>
  ) 
}
const styles = StyleSheet.create({
  button: {
    marginTop: 15
  },
  title: {
    fontSize: 14,
    fontWeight: 'bold',
    paddingBottom: 10
  },
  pickerCountry: {
    minWidth: '40%',
    padding: 15
  },
  card: {
    width: '100%',
    flexDirection: 'row'
  },
  body: {
    backgroundColor: colors.backgroundColor,
    padding: 15,
    alignItems: isWeb() ? 'center' : null
  },
  container: {
    width: isWeb() ? 500 : null
    
  }
})
export default AddNewCard;