import React, { useState } from 'react'
import { View, StyleSheet, Text, Alert } from 'react-native'
import { colors } from '../utils/colors'
import Header from '../components/Header'
import Input from '../components/Input'
import Button from '../components/Button'
import { useDispatch } from 'react-redux'
import { resetPassword } from '../redux/auth/actions'
import { isWeb } from '../utils/isweb'

const ForgotPassword = ({ navigation }) => {
  const dispatch = useDispatch()
  const [values, setValues] = useState({});

  const onChange = (key, value) => {
    setValues({
      ...values,
      [key]: value
    })
  }

  const onReset = () => {
    if (!values.email) {
      Alert.alert('Please enter all fields')
    } else {
      dispatch(resetPassword(values, navigation))
    }
  }
  return (
    <View style={styles.body}>
      <View>
        <Header title="" onPress={() => navigation.navigate('Login')} />
        <Text style={styles.title}> Forgot Password</Text>
        <Text style={styles.titleContent}> Enter your email address to recieve instructions for password reset </Text>
        <Input onChangeText={(v) => onChange('email', v)} value={values.email} title="Email" placeholder="Your email id" />
        <Button onPress={() => onReset()} name="Reset password" backgroundColor={colors.blue} color={colors.white}/>
        <Button onPress={() => navigation.goBack('')} name="Cancel" backgroundColor={colors.cancelColor} color={colors.blue}/>
   
      </View>
    </View>
  ) 
}
const styles = StyleSheet.create({
  title: {
    fontSize: 20,
    color: colors.blue,
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: 40,
    marginBottom: 15
  },
  titleContent: {
    fontSize: 16,
    color: colors.textColor,
    textAlign: 'center',
    paddingHorizontal: 20,
    marginBottom: 30
  },
  body: {
    padding: 15,
    backgroundColor: colors.backgroundColor,
    alignItems: isWeb() ? 'center' : null,

  }
})
export default ForgotPassword;