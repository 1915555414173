import React, { useEffect, useState } from 'react'
import { View, StyleSheet, Text, TouchableOpacity, Dimensions, SafeAreaView, FlatList } from 'react-native'
import { colors } from '../utils/colors'
import { useDispatch, useSelector } from 'react-redux'
import { getSharedEmails } from '../redux/app/actions'
import Header from '../components/Header'
// import { ScrollView } from 'react-native-gesture-handler'
import { isWeb } from '../utils/isweb'
import WebHeader from '../components/WebHeader'
// import InputSearch from '../components/InputSearch'

const { height } = Dimensions.get('window');
const SharedData = ({ navigation, route }) => {
  const { item } = route?.params || { };
  console.log(item)

  const [show, setShow] = useState(0)
  const [isLoading] = useState(false)
  const dispatch = useDispatch()
  const profile = useSelector((state) => state.App.profile);
  const client = useSelector((state) => state.App.lawyerClients);
  // const filesList = useSelector((state) => state.App.filesList)
  const sharedEmails = useSelector((state) => state.App.sharedEmails)
  // const list = filesList?.results?.filter((o) => o.file_section === item)

  console.log(client)

  
  useEffect(() => {
    if (profile.user_type === 'lawyer') {
      dispatch(getSharedEmails(client[0].customer.id))
    } else {
      dispatch(getSharedEmails(profile.id))
    }
  }, []);

    
  return (
    <SafeAreaView>
      <View style={styles.body}>
        {isWeb() ? (
          <>
            <WebHeader navigation={navigation} />
            <Header
              width="50%"
              onPress={() => navigation.goBack('')}
              title="Shared Data"
            />
          </>
        ) : (
          <Header onPress={() => navigation.goBack('')} title="Shared Data" />
        )}
        {sharedEmails ? (
          <View style={{ flex: 1,
            padding: 24 }}>
            {isLoading ? (
              <Text>Loading...</Text>
            ) : (
              <View>
                <Text
                  style={{
                    fontSize: 20,
                    color: '#000',
                    textAlign: 'center',
                    paddingBottom: 20
                  }}
                >
                  My Shared Data
                </Text>
                
                <FlatList
                  style={{ marginTop: 20 }}
                  data={sharedEmails}
                  keyExtractor={({ id }) => id}
                  renderItem={({ item }) => (
                    <View>
                      <TouchableOpacity
                        onPress={() => {
                          setShow(item.id)
                        }}
                      >
                        <View style={{ flexDirection: 'row' }}>
                          <Text style={{ color: colors.blue,
                            fontSize: 16, 
                            width: '92%' }}>
                            {item.subject}
                          </Text>
                        </View>
                        <View style={{ flexDirection: 'row' }}>
                          <Text
                            style={{
                              color: 'grey',
                              paddingBottom: 35,
                              width: '92%',
                              fontSize: 12
                            }}
                          >
                            {item.sender}
                          </Text>
                        </View>
                      </TouchableOpacity>
                      {show !== item.id ? null : (
                        <Text style={{ paddingBottom: 20 }}>
                          {item.body.message}
                        </Text>
                      )}
                      
                    </View>
                  )}
                />
              </View>
            )}
          </View>
        ) : (
          <Text style={{ marginVertical: 50 }}>
            This section is empty, shared data will appear here.
          </Text>
        )}
      </View>
    </SafeAreaView>
    // </ScrollView>
  )
}
const styles = StyleSheet.create({
  buttonTextDelete: {
    color: 'red',
    fontWeight: 'bold'
  },
  buttonTextShare: {
    color: 'green',
    fontWeight: 'bold'
  },
  buttonText: {
    color: colors.blue,
    fontWeight: 'bold'
  },
  button: {
    paddingHorizontal: 25,
    paddingVertical: 15
  },
  detailsCont: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  details: {
    padding: 20
  },
  type: {
    fontSize: 12,
    color: colors.textColor,
    fontWeight: '400'
  },
  icon: {
    width: 25,
    height: 25,
  },
  filterBar: {
    paddingHorizontal: 20,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  searchContainer: {
    padding: 15,
    marginTop: 20,
    backgroundColor: colors.white,
    width: isWeb() ? '50%' : '95%',
    borderRadius: 10
  },
  text: {
    fontSize: 20,
    margin: 22,
    color: colors.white,
    letterSpacing: 12,
    fontWeight: 'bold'
  },
  body: {
    paddingBottom: 10,
    padding: 15,
    alignItems: 'center',
    height
  }
})
export default SharedData;