import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import { userFiles } from '../../redux/app/actions';
import './index.scss'
import { Linking, Text, View } from 'react-native';
import { BASE_URL } from '../../utils/http';

const uploadWeb = () => {
  const dispatch = useDispatch()
  const applications = useSelector((state) => state.App.applications)
  const profile = useSelector((state) => state.App.profile)
  const [modal, setModal] = useState(false)
  const [email, setEmail] = useState(false)
  console.log('applications :>> ', applications);

  const fileChangedHandler = (event, section) => {
    const file = event?.target?.files[0];

    dispatch(userFiles(file, section))
    setModal(false)
  };

  const emailHandler = () => {
    setModal(!modal)
    setEmail(!email)
  }

  const authorize = () => {
    fetch(`${BASE_URL}/api/v1/accounts/email/`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify(profile.id)
    })
      .then((response) => response.json())
      .then((json) => { 
        setEmail(false) 
        Linking.openURL(json) 
      })
      .catch((error) => console.error(error))
      .finally(() => console.log('Emails'))
  }

  return (
    <div>
      <Button onClick={() => setModal(!modal)} style={{
        width: 540,
        borderRadius: 10,
        backgroundColor: '#006ed6',
        color: 'white',
        marginTop: 20,
      }} variant="contained" component="span">
        Upload
      </Button>

      {
        modal
          ? <div id="myModal" className="modal">
            <div className="modal-content">
              <span onClick={() => setModal(!modal)} className="close">&times;</span>
              <p className="modal-title">Please select section and upload youre file</p>

              <div className="contentTitle">
                <label className="contentLabel" htmlFor="contained-button-files">
                  <input
                    onChange={(event) => fileChangedHandler(event, 'files')}
                    type="file"
                    accept="*"
                    style={{ display: 'none' }}
                    id="contained-button-files"
                  /><p>Files</p>
                  <input accept="*" id="icon-button-file"
                    type="file" style={{ display: 'none' }} />
                </label>
              </div>

              <div className="contentTitle">
                <label className="contentLabel" htmlFor="contained-button-videos">
                  <input
                    onChange={(event) => fileChangedHandler(event, 'videos')}
                    type="file"
                    accept="*"
                    style={{ display: 'none' }}
                    id="contained-button-videos"
                  /><p>Videos</p>
                  <input accept="*" id="icon-button-videos"
                    type="file" style={{ display: 'none' }} />
                </label>
              </div>

              <div className="contentTitle">
                <label className="contentLabel" htmlFor="contained-button-emails">
                  <View> 
                    <Text onPress={() => emailHandler()}>Emails</Text>
                  </View>
                </label>
              </div>

              <div className="contentTitle">
                <label className="contentLabel" htmlFor="contained-button-calls">
                  <input
                    onChange={(event) => fileChangedHandler(event, 'calls')}
                    type="file"
                    accept="*"
                    style={{ display: 'none' }}
                    id="contained-button-calls"
                  /><p>Calls</p>
                  <input accept="*" id="icon-button-file"
                    type="file" style={{ display: 'none' }} />
                </label>
              </div>

              <div className="contentTitle">
                <label className="contentLabel" htmlFor="contained-button-sms">
                  <input
                    onChange={(event) => fileChangedHandler(event, 'sms')}
                    type="file"
                    accept="*"
                    style={{ display: 'none' }}
                    id="contained-button-sms"
                  /><p>SMS</p>
                  <input accept="*" id="icon-button-file"
                    type="file" style={{ display: 'none' }} />
                </label>
              </div>

              <div className="contentTitle">
                <label className="contentLabel" htmlFor="contained-button-recordings">
                  <input
                    onChange={(event) => fileChangedHandler(event, 'recordings')}
                    type="file"
                    accept="*"
                    style={{ display: 'none' }}
                    id="contained-button-recordings"
                  /><p>Recordings</p>
                  <input accept="*" id="icon-button-file"
                    type="file" style={{ display: 'none' }} />
                </label>
              </div>

            </div>
          </div>
          : null
      }

      {
        email
          ? <div id="myModal" className="modal">
            <div className="modal-content">
              <span onClick={() => setEmail(!email)} className="close">&times;</span>
              <p className="modal-title">Connect Your Mailbox</p>


              <div className="contentTitle">
                <label className="contentLabel" htmlFor="contained-button-emails">
                  <View> 
                    <Button onClick={() => authorize()} style={{
                      width: 340,
                      alignSelf: 'center',
                      borderRadius: 10,
                      backgroundColor: '#006ed6',
                      color: 'white',
                      // marginTop: 20,
                    }} variant="contained" component="span">
        Authorize
                    </Button>
                  </View>
                </label>
              </div>


            </div>
          </div>
          : null
      }

    </div>
  )
}

export default uploadWeb;