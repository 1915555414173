import React, { useEffect, useState } from 'react'
import {
  View,
  StyleSheet,
  Text,
  TouchableOpacity,
  Dimensions,
  SafeAreaView,
  FlatList
} from 'react-native'
import { colors } from '../utils/colors'
import { useSelector } from 'react-redux'
import Header from '../components/Header'
import { ScrollView } from 'react-native-gesture-handler'
import { isWeb } from '../utils/isweb'
import WebHeader from '../components/WebHeader'
import { BASE_URL } from '../utils/http'
import WebView from 'react-native-webview'

const { height } = Dimensions.get('window')
const CallRecords = ({ navigation }) => {
  const [isLoading, setLoading] = useState(true)
  const profile = useSelector((state) => state.App.profile)
  const [data, setData] = useState([])
  const [show, setShow] = useState(0)
  // console.log(data);

  useEffect(() => {
    fetch(`${BASE_URL}/api/v1/accounts/record/${profile.id}/`)
      .then((response) => response.json())
      .then((json) => setData(json))
      .catch((error) => console.error(error))
      .finally(() => setLoading(false))
  }, [])
  return (
    <ScrollView>
      <SafeAreaView>
        <View style={styles.body}>
          {isWeb() ? (
            <>
              <WebHeader navigation={navigation} />
              <Header
                width="50%"
                onPress={() => navigation.goBack('')}
                title="Call Recordings List"
              />
            </>
          ) : (
            <Header
              onPress={() => navigation.goBack('')}
              title="Call Recording Lists"
            />
          )}
          {data ? (
            <View style={{ flex: 1,
              padding: 24 }}>
              {isLoading ? (
                <Text>Loading...</Text>
              ) : (
                <View
                  style={{
                    flex: 1,
                    flexDirection: 'column',
                    justifyContent: 'space-between'
                  }}
                >
                  <Text
                    style={{
                      fontSize: 18,
                      color: 'green',
                      textAlign: 'center'
                    }}
                  >
                    {data.subject}
                  </Text>
                  <Text
                    style={{
                      fontSize: 20,
                      color: '#000',
                      textAlign: 'center',
                      paddingBottom: 20
                    }}
                  >
                    My Voice Call Records
                  </Text>
                  <FlatList
                    data={data}
                    keyExtractor={({ id }) => id}
                    renderItem={({ item }) => (
                      <View>
                        <TouchableOpacity
                          onPress={() => {
                            setShow(item.id)
                          }}
                        >
                          <Text
                            style={{
                              color: 'green',
                              fontSize: 16,
                              marginBottom: 10
                            }}
                          >
                            {`Shiny${item.recording_sid}${item.id}`}{' '}
                          </Text>
                        </TouchableOpacity>
                        {show !== item.id ? null : (
                          <WebView
                            source={{ uri: item.recording_url }}
                            style={{ height: 80,
                              marginBottom: 10 }}
                          />
                        )}
                      </View>
                    )}
                  />
                </View>
              )}
            </View>
          ) : (
            <Text style={{ marginVertical: 50 }}>
              This section is empty, please add recordings.
            </Text>
          )}
        </View>
      </SafeAreaView>
    </ScrollView>
  )
}
const styles = StyleSheet.create({
  buttonTextDelete: {
    color: 'red',
    fontWeight: 'bold'
  },
  buttonText: {
    color: colors.blue,
    fontWeight: 'bold'
  },
  button: {
    paddingHorizontal: 25,
    paddingVertical: 15
  },
  detailsCont: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  details: {
    padding: 20
  },
  type: {
    fontSize: 12,
    color: colors.textColor,
    fontWeight: '400'
  },
  icon: {
    width: 25,
    height: 25
  },
  filterBar: {
    paddingHorizontal: 20,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  searchContainer: {
    padding: 15,
    marginTop: 20,
    backgroundColor: colors.white,
    width: isWeb() ? '50%' : '95%',
    borderRadius: 10
  },
  text: {
    fontSize: 20,
    margin: 22,
    color: colors.white,
    letterSpacing: 12,
    fontWeight: 'bold'
  },
  body: {
    paddingBottom: 10,
    padding: 15,
    alignItems: 'center',
    height
  }
})
export default CallRecords