import React from 'react'
import { StyleSheet, Text, Image, TouchableOpacity } from 'react-native'
import { colors } from '../utils/colors';

const FeatureCard = ({ title, image, onPress, mini }) => (
  <TouchableOpacity onPress={onPress} style={[styles.cardContainer, { flexDirection: mini ? 'row' : null }]}>
    <Image
      style={[
        styles.icon, { width: mini ? 25 : 50,
          height: mini ? 25 : 50, }
      ]}
      resizeMode="contain"
      source={image}
    />    
    <Text numberOfLines={1} style={[
      styles.title, { color: mini ? '#0069d8' : colors.textColor,
        fontWeight: mini ? 'bold' : null,
        marginTop: mini ? 0 : 10, }
    ]}>{title}</Text>
  </TouchableOpacity>
)
const styles = StyleSheet.create({
  title: {
    fontSize: 14,
    textAlign: 'center',
    width: 90,
    justifyContent: 'center',
    alignItems: 'center'
  },
  icon: {

  },
  cardContainer: {
    borderRadius: 10,
    padding: 15,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.white,
    flex: 1,
    margin: 10
  },
})
export default FeatureCard;