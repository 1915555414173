import React, { useEffect } from 'react'
import { Provider } from 'react-redux'
import 'react-native-gesture-handler'
import SendBird from 'sendbird';

import Navigation from './Navigation'
import { store } from './redux/store'
import { setupHttpConfig } from './utils/http'
import { AppContext } from './context';
import { StatusBar } from 'react-native';


const appId = '268785D6-CB76-4C27-82FE-E3A65EFE74C6';
const sendbird = new SendBird({ appId });
sendbird.setErrorFirstCallback(true);

const initialState = {
  sendbird
};

const App = () => {
  useEffect(() => {
    setupHttpConfig()
  }, [])

  return (
    <AppContext.Provider value={initialState}>
      <StatusBar barStyle="dark-content" />
      <Provider store={store}>
        <Navigation />
      </Provider>
    </AppContext.Provider>
  )
}

export default App;  