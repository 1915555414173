/* eslint-disable no-unused-vars */
import 'react-native-gesture-handler';

import React, { useState, useEffect } from 'react';
import { NavigationContainer } from '@react-navigation/native'
import { createStackNavigator } from '@react-navigation/stack'
import { useDispatch, useSelector } from 'react-redux';
import SplashScreen from './screens/SplashScreen';
import Login from './screens/Login'
import WelcomeScreen from './screens/WelcomeScreen'
import SignUp from './screens/SignUp';
import SuggestImprovements from './screens/SuggestImprovements'
import ForgotPassword from './screens/ForgotPassword';
import TokenInput from './screens/TokenInput';
import SetNewPassword from './screens/SetNewPassword';
import Profile from './screens/Profile'
import HomeDashboard from './screens/HomeDashboard';
import MessageCenter from './screens/MessageCenter';
import AddNewCard from './screens/AddNewCard';
import Membership from './screens/Membership';
import Files from './screens/Files'
import Chat from './screens/Chat';
import StorageUtils from './utils/storage';
import { addTokenToHttp } from './utils/http';
import { setUser } from './redux/auth/actions';
import { getProfile } from './redux/app/actions';
import ChatInvite from './screens/ChatInvite';
import Emails from './screens/Emails';
import CallRecords from './screens/CallRecords';
import FilesWeb from './screens/Files.web';
import SharedData from './screens/SharedData';


const isReadyRef = React.createRef();
const navigationRef = React.createRef();

const Stack = createStackNavigator()

const Navigation = () => {
  const dispatch = useDispatch();

  let token = null;
  const [isMounted, setIsMounted] = useState(null);
  const accessToken = useSelector((state) => state.Auth.accessToken);
  const profile = useSelector((state) => state.App.profile)
  
  useEffect(() => () => {
    isReadyRef.current = false
  }, []);
  
  useEffect(() => {
    (async () => {
      try {
        token = await StorageUtils.getAccessToken();
        const email = await StorageUtils.getUser();
        dispatch(setUser(email, token))
        addTokenToHttp(token)
        if (token) {
          dispatch(getProfile())
        }
      } catch (e) {
        console.log('e', e)
      }
    })()
    
    setTimeout(() => {
      setIsMounted(true)
    }, 200);
  }, [])

  const isLogggedIn = Boolean(accessToken || token)

  return (
    <NavigationContainer >
      {isLogggedIn || !isMounted ? (
        <Stack.Navigator screenOptions={{ headerShown: false }}>
          <Stack.Screen name="SplashScreen" component={SplashScreen} />
          <Stack.Screen name="HomeDashboard" component={HomeDashboard} />
          <Stack.Screen name="Profile" component={Profile} />
          <Stack.Screen name="MessageCenter" component={MessageCenter} />
          <Stack.Screen name="ChatInvite" component={ChatInvite} />
          <Stack.Screen name="Chat" component={Chat} />
          <Stack.Screen name="AddNewCard" component={AddNewCard} />
          <Stack.Screen name="Membership" component={Membership} />
          <Stack.Screen name="Files" component={Files} />
          <Stack.Screen name="Emails" component={Emails} />
          <Stack.Screen name="CallRecords" component={CallRecords} />
          <Stack.Screen name="FilesWeb" component={FilesWeb} />
          <Stack.Screen name="SharedData" component={SharedData} />
        </Stack.Navigator>
      ) : (
        <Stack.Navigator screenOptions={{ headerShown: false }}>
          <Stack.Screen name="WelcomeScreen" component={WelcomeScreen} />
          <Stack.Screen name="SetNewPassword" component={SetNewPassword} />
          <Stack.Screen name="TokenInput" component={TokenInput} />
          <Stack.Screen name="ForgotPassword" component={ForgotPassword} />
          <Stack.Screen name="SignUp" component={SignUp} />
          <Stack.Screen name="Login" component={Login} />
          <Stack.Screen name="SuggestImprovements" component={SuggestImprovements} />
        </Stack.Navigator>
      )
      }
    </NavigationContainer>
  )
};
export function navigate (name, params) {
  if (isReadyRef.current && navigationRef.current) {
    navigationRef.current.navigate(name, params);
  }
}
export default Navigation;