import React from 'react';
import { 
  StyleSheet,
  View,
  Text,
  Image
} from 'react-native';
import { colors } from '../utils/colors';
import Button from '../components/Button';

const MemberShipCard = ({ price, content, check1, check2, check3, image, safe, updatePlan }) => (
  <View style={styles.body}>
    <View style={styles.container}>
      <Image
        style={styles.icon}
        resizeMode="contain"
        source={image}
      />    
      <Text style={styles.iconText}>{price}</Text>
      { safe ? <Text style={styles.textSave}>{safe}</Text> : null }
      <Text style={styles.iconData} numberOfLines={3}>{content}</Text>
      <View style={styles.checkContainer}>
        <Image
          style={styles.iconCheck}
          resizeMode="contain"
          source={require('../assets/checkCircle.png')}
        />    
        <Text style={styles.checkText}>{check1}</Text>
      </View>
      <View style={styles.checkContainer}>
        <Image
          style={styles.iconCheck}
          resizeMode="contain"
          source={require('../assets/checkCircle.png')}
        />    
        <Text style={styles.checkText}>{check2}</Text>
      </View>
      <View style={styles.checkContainer}>
        <Image
          style={styles.iconCheck}
          resizeMode="contain"
          source={require('../assets/checkCircle.png')}
        />    
        <Text style={styles.checkText}>{check3}</Text>
      </View>
      <View style={styles.button}>
        <Button onPress={updatePlan} name="Update plan" backgroundColor={colors.blue} color={colors.white}/>
        <Button name="Add to charity" backgroundColor={colors.cancelColor} color={colors.blue}/>
      </View>
    </View>
  </View>
)

const styles = StyleSheet.create({
  button: {
    marginTop: 20,
    width: '100%'
  },
  checkText: {
    fontSize: 16,
    color: colors.textColor,
    marginLeft: 15
  },
  checkContainer: {
    flexDirection: 'row',
    width: '85%',
    marginTop: 15
  },
  iconCheck: {
    width: 25,
    height: 25
  },
  iconData: {
    fontSize: 16,
    color: colors.textColor,
    marginTop: 0,
    padding: 20
  },
  textSave: {
    fontSize: 15,
    color: colors.blue,
    fontWeight: 'bold',
    marginTop: 5
  },
  iconText: {
    fontSize: 20,
    color: colors.blue,
    fontWeight: 'bold',
    marginTop: 10
  },
  icon: {
    width: 38,
    height: 38
  },
  container: {
    marginTop: 25,
    justifyContent: 'center',
    alignItems: 'center',
  },
  body: {
    marginVertical: 20,
    backgroundColor: colors.white,
  }
});

export default React.memo(MemberShipCard);