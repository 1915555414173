import React from 'react';
import {
  StyleSheet,
  View,
  Text,
  Image,
  TouchableOpacity,
  SafeAreaView,
} from 'react-native';
import { colors } from '../utils/colors';

const ChatHeader = ({ navigation, name }) => (
  <>
    <SafeAreaView />
    <View style={styles.backpanel}>
      <TouchableOpacity onPress={() => navigation.goBack('')}>
        <Image style={styles.iconBack} source={require('../assets/back.png')} />   
      </TouchableOpacity> 
      <Image style={styles.avatar} source={require('../assets/profile2.png')} />  
      <View style={styles.supportContainer}>
        <Text style={styles.support}>{name}</Text>
      </View>
      <View style={styles.space} />
    </View>
  </>
)
const styles = StyleSheet.create({
  iconBack: {
    width: 20,
    height: 20,
    resizeMode: 'cover',
  },
  supportContainer: {
    padding: 0,
  },
  backpanel: {
    padding: 10,
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10
  },
  avatar: {
    width: 50,
    height: 50,
    marginLeft: 25,
    marginRight: 10,
    resizeMode: 'cover',
    borderRadius: 30,
  },
  space: {
    width: 20,
  },
  support: {
    fontWeight: 'bold',
    color: colors.black,
    fontSize: 17
  }
});
export default ChatHeader;