import React, { useEffect, useState } from 'react'
import { View, StyleSheet, Text, TouchableOpacity, Image, Linking, Dimensions, SafeAreaView } from 'react-native'
import { colors } from '../utils/colors'
import { useDispatch, useSelector } from 'react-redux'
import { fileDelete, getUserFiles, userFiles } from '../redux/app/actions'
import Header from '../components/Header'
import { ScrollView } from 'react-native-gesture-handler'
import { isWeb } from '../utils/isweb'
import WebHeader from '../components/WebHeader'
import { useDropzone } from 'react-dropzone'

const { height } = Dimensions.get('window');
const FilesWeb = ({ navigation, route }) => {
  const { item } = route?.params || { };

  const [show, setShow] = useState(0)
  const [modal, setModal] = useState(0)
  const [files, setFiles] = useState([])
  const dispatch = useDispatch()
  const filesList = useSelector((state) => state.App.filesList)
  const detailsFile = useSelector((state) => state.App.detailsFile)
  const list = filesList?.results?.filter((o) => o.file_section === item)

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ 
    // accept: '*',
    onDrop: (acceptedFiles) => {
      console.log(files)
      dispatch(userFiles(acceptedFiles[0], 'files'))
      setFiles(acceptedFiles.map((file) => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })))
    }
  })
  
  
  useEffect(() => {
    dispatch(getUserFiles())
  }, []);
  const onModal = (id) => {
    setModal(id)
  }

  const onDelete = (id) => {
    dispatch(fileDelete(id))
    dispatch(getUserFiles())
  }
  // const onDetails = (id) => {
  //   dispatch(fileDetails(id))
  //   setShow(id)
  // }
    
  return (
    <ScrollView>
      <SafeAreaView>
        <View style={styles.body}>
          {isWeb() ? (
            <>
              <WebHeader navigation={navigation}/> 
              <Header width="50%" onPress={() => navigation.goBack('')} title="File List"/>
            </>
          ) : (
            <Header onPress={() => navigation.goBack('')} title="File List"/>
          )}
          {   
            list?.length 
              ? list.map((item) => <><React.Fragment key={item.id}>
                <View style={styles.searchContainer}>
                  <View style={styles.filterBar}>
                    <View>
                      <TouchableOpacity
                        onPress={() => {
                          Linking.openURL(item.file)
                        } }>
                        <Text style={{ fontWeight: 'bold' }}>{item.filename}</Text>
                      </TouchableOpacity>
                      <Text style={styles.type}>{item.mime_type}</Text>
                    </View>
                    {show === item.id || modal === item.id ? null
                      : <TouchableOpacity onPress={() => onModal(item.id)}>
                        <Image resizeMode="contain" style={styles.icon} source={require('../assets/trash.png')} />
                        {/* <Text style={{ color: colors.blue,
            fontWeight: 'bold' }}>Delet</Text> */}
                      </TouchableOpacity>}
                  </View>
                  {show === item.id
                    ? <View style={styles.details}>
                      <Text>{detailsFile.created}</Text>
                      <Text>{detailsFile.updated}</Text>
                      <Text>id: {detailsFile.id}</Text>
                      <View style={styles.detailsCont}>
                        <Text>user: {detailsFile.user}</Text>
                        <TouchableOpacity onPress={() => setShow()}><Text style={{ color: colors.blue }}>Close</Text></TouchableOpacity>
                      </View>
                    </View>
                    : null}
                  {modal === item.id
                    ? <>
                      <View style={{
                        flexDirection: 'row',
                        justifyContent: 'center'
                      }}>
                        <TouchableOpacity style={styles.button} onPress={() => onDelete(item.id)}>
                          <Text style={styles.buttonTextDelete}>Yes delete</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={styles.button} onPress={() => setModal(0)}>
                          <Text style={styles.buttonText}>Cancel</Text>
                        </TouchableOpacity>
                      </View>
                    </>
                    : null}
                </View>

              </React.Fragment></>)
              
           
              : <>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  {
                    isDragActive
                      ? <p>Drop the files here ...</p>
                      : <p>Drag 'n' drop some files here, or click to select files</p>
                  }
                </div>
              </>
          }
          <>
            {list.length ? (<div {...getRootProps()}>
              <input {...getInputProps()} />
              {
                isDragActive
                  ? <p>Drop the files here ...</p>
                  : <p>Drag 'n' drop some files here, or click to select files</p>
              }
            </div>) : null}
          </>
        </View>
      </SafeAreaView>
    </ScrollView>
  ) 
}
const styles = StyleSheet.create({
  buttonTextDelete: {
    color: 'red',
    fontWeight: 'bold'
  },
  buttonText: {
    color: colors.blue,
    fontWeight: 'bold'
  },
  button: {
    paddingHorizontal: 25,
    paddingVertical: 15
  },
  detailsCont: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  details: {
    padding: 20
  },
  type: {
    fontSize: 12,
    color: colors.textColor,
    fontWeight: '400'
  },
  icon: {
    width: 25,
    height: 25,
  },    
  filterBar: {
    paddingHorizontal: 20,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  searchContainer: {
    padding: 15,
    marginTop: 20,
    backgroundColor: colors.white,
    width: isWeb() ? '50%' : '95%',
    borderRadius: 10,
  },
  text: {
    fontSize: 20,
    margin: 22,
    color: colors.white,
    letterSpacing: 12,
    fontWeight: 'bold'
  },
  body: {
    paddingBottom: 10,
    padding: 15,
    alignItems: 'center',
    height
  }
})
export default FilesWeb;