import React, { useState } from 'react';
import {  
  View,
  Text,
  StyleSheet,
  useWindowDimensions,
  TouchableOpacity,
  ScrollView,
  Alert,
  SafeAreaView
} from 'react-native';
import { useDispatch } from 'react-redux'
import { signUp } from '../redux/auth/actions'
import { TabView, SceneMap, TabBar } from 'react-native-tab-view';
import { colors } from '../utils/colors'
import Logo from '../components/Logo'
import Input from '../components/Input';
import Button from '../components/Button';
import SocialLogin from '../components/SocialLogin';
import { isWeb } from '../utils/isweb';
// import SocialLoginWeb from '../components/SocialLogin.web';

const Form = (props) => {
  const { navigation, userType, item, changed, setChanged } = props;
  const dispatch = useDispatch()
  const [values, setValues] = useState({
    user_type: userType,
    surname: ' ',
  });

  React.useEffect(() => {
    if (item !== userType && !changed) {
      props.jumpTo(item)
      setChanged(true)
    }
  }, [item])

  const onSubmit = () => {
    if (!values.email || !values.password) {
      Alert.alert('Please enter all fields')
    } else if (values.password !== values.confirm_password) {
      Alert.alert('Passwords do not match')
    } else {
      dispatch(signUp({
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        phone: values.phone,
        password: values.password,
        user_type: values.user_type,
        profile: {
          date_of_birth: values.date_of_birth,
          location: values.location
        }
      }))
    }
  }

  const onChange = (key, value) => {
    setValues({
      ...values,
      [key]: value
    })
  }

  return (
    <ScrollView>
      <View style={styles.body}>
        <View style={styles.firstContainer} >
        
          <Input onChangeText={(v) => onChange('email', v)} value={values.email} title="Email" placeholder="Your email id " />
          <Input onChangeText={(v) => onChange('phone', v)} value={values.phone} title="Phone" placeholder="Phone format: +12025550136 " />
          {
            userType === 'lawyer' 
              ? <>
                <Input onChangeText={(v) => onChange('first_name', v)} value={values.first_name} title="First Name" placeholder="First name " />
                <Input onChangeText={(v) => onChange('last_name', v)} value={values.last_name} title="Last Name" placeholder="Last name " />
                <Input onChangeText={(v) => onChange('date_of_birth', v)} value={values.date_of_birth} title="Date of Birth" placeholder="Your birth date (forrmat: 1997-05-21) " />
                <Input onChangeText={(v) => onChange('location', v)} value={values.location} title="Location" placeholder="Location " />
              </>
              : null
          }
          <Input onChangeText={(v) => onChange('password', v)} value={values.password} title="Password" placeholder="Password" password={true} />
          <Input onChangeText={(v) => onChange('confirm_password', v)} value={values.confirm_password} title="Confirm Password" placeholder="Confirm password" password={true} />
     
          <Button onPress={onSubmit} name="Sign up" backgroundColor={colors.blue} color={colors.white}/>
          <Button onPress={() => navigation.navigate('WelcomeScreen')} name="Cancel" backgroundColor={colors.cancelColor} color={colors.blue}/>

          <View style={styles.signupContainer} >
            <Text style={styles.textContainer}>Already have an account? </Text>
            <TouchableOpacity onPress={() => navigation.navigate('Login', { item: userType })} >
              <Text style={styles.textContainerSignUp}> Login</Text>
            </TouchableOpacity>
          </View>
          {
            isWeb() ? <View style={styles.loginWitchContainer}>
              <Text style={styles.loginWitch}>Or sign up with</Text>
              <SocialLogin userType={values.user_type} />
            </View> : <View style={styles.loginWitchContainer}>
              <Text style={styles.loginWitch}>Or sign up with</Text>
              <SocialLogin userType={values.user_type} />
            </View>
          }
        
        </View>
      </View>
    </ScrollView>
  ) 
};

const SignUp = ({ navigation, route }) => {
  const { item } = route.params;
  const [changed, setChanged] = React.useState(false)
  const layout = useWindowDimensions();
  const [index, setIndex] = React.useState(1);
  const [routes] = React.useState([
    { key: 'lawyer',
      title: 'I’m a Lawyer' },
    { key: 'customer',
      title: 'I’m a Customer' },
  ]);

  const renderScene = SceneMap({
    lawyer: (props) => <Form changed={changed} setChanged={setChanged} userType="lawyer" navigation={navigation} item={item} {...props} />,
    customer: (props) => <Form changed={changed} setChanged={setChanged} userType="customer" navigation={navigation} item={item} {...props} />,
  });
  const renderTabBar = (props) => (
    <View style={{
      alignItems: isWeb() ? 'center' : null
    }}>
      <TabBar
        {...props}
        indicatorStyle={{ backgroundColor: colors.blue }}
        style={{ backgroundColor: colors.whiteBackground,
          width: isWeb() ? 500 : null,
          color: colors.blue, }}
        getLabelText={({ route }) => route.title}
        renderLabel={({ route }) => (
          <View>
            <Text style={{ fontSize: 16,
              fontWeight: 'bold',
              textAlign: 'center',
              color: route.key === props.navigationState.routes[props.navigationState.index].key
                ? colors.black : colors.textColor }}>
              {route.title}
            </Text>
          </View>
        )}
      />
    </View>
  );
  return (
    <SafeAreaView style={{ flex: 1,
      backgroundColor: colors.whiteBackground }}>
      <View style={styles.logoContainer}>
        <Logo 
          imageLogo={require('../assets/logoBlue.png')} 
          color={colors.blue}
        />
      </View>
      <TabView
        renderTabBar={renderTabBar}
        navigationState={{ index,
          routes }}
        renderScene={renderScene}
        onIndexChange={setIndex}
        initialLayout={{ width: layout.width }}
        style={{ backgroundColor: colors.whiteBackground }}
      />
    </SafeAreaView>
  );
}
const styles = StyleSheet.create({
  loginWitch: {
    fontSize: 15,
    fontWeight: 'bold'

  },
  loginWitchContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 20
  },
  textContainerSignUp: {
    fontSize: 15,
    color: colors.blue,
    fontWeight: 'bold',
    textDecorationLine: 'underline'
  },
  textContainer: {
    fontWeight: 'bold',
    fontSize: 15
  },
  signupContainer: {
    marginTop: 15,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },  
  firstContainer: {
    paddingTop: 20,
    paddingBottom: 20,
    width: isWeb() ? 500 : null,

  },
  logoContainer: {
    backgroundColor: colors.whiteBackground,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 25,

  },
  body: {
    backgroundColor: colors.backgroundColor,    
    alignItems: isWeb() ? 'center' : null,
    
  }
})
export default SignUp;