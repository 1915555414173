/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { 
  View,
  Alert,
  StyleSheet,
  ScrollView,
  Text,
  Image,
  TouchableOpacity,
  SafeAreaView,
  Linking,
  Dimensions
} from 'react-native'
import Dialog, { DialogContent } from 'react-native-popup-dialog';

import { useDispatch, useSelector } from 'react-redux'
import ActionSheet from '../components/ActionSheet';
import Button from '../components/Button'
import FeatureCard from '../components/FeatureCard'
import Input from '../components/Input';
import InputSearch from '../components/InputSearch'
import UploadWeb from '../components/UploadWeb/upload';
import WebHeader from '../components/WebHeader';
import { coustomerInvite, fileDelete, getSearch, getUserFiles, lawyerInvite, userFiles } from '../redux/app/actions'
import { colors } from '../utils/colors'
import { isWeb } from '../utils/isweb';

const { height } = Dimensions.get('window');

const HomeDashboard = ({ navigation }) => {
  const profile = useSelector((state) => state.App.profile)
  const filesList = useSelector((state) => state.App.filesList)
  const dispatch = useDispatch()
  const [visible, setVisible] = useState(false)
  const [show, setShow] = useState(false)
  const [collab, setCollab] = useState(false)
  const [values, setValues] = React.useState({});
  const [search, setSearch] = useState({})
  const [find, setFind] = useState('filename')
  const [modal, setModal] = useState(0)
  const [placehold, setPlaceholder] = useState('Search...')
  const [optionsModalVisible, setModalVisible] = useState(false);
  const options = [
    'Files',
    'Videos',
    'Recordings',
    'Emails',
    'Call',
    'SMS'
  ];
  
  useEffect(() => {
    dispatch(getUserFiles())
  }, []);

  const list = filesList?.results?.filter((o) => o?.[find]?.toLowerCase().includes(search?.findName?.toLowerCase()))


  const onInvite = () => {
    if (!values.customer_email || !values.title || !values.message) {
      Alert.alert('Please enter all fields')
    } else if (profile.user_type === 'customer') {
      dispatch(coustomerInvite(values))
    } else if (profile.user_type === 'lawyer') {
      dispatch(lawyerInvite(values))
    }
  }
  const onChange = (key, value) => {
    setValues({
      ...values,
      [key]: value
    })
  }
  
  const onSearch = (key, value) => {
    setVisible(true)
    setSearch({
      ...search,
      [key]: value
    })
  }

  const onModal = (id) => {
    setModal(id)
  }

  const onDelete = (id) => {
    dispatch(fileDelete(id))
    dispatch(getUserFiles())
  }

  useEffect(() => {
    if (find === 'updated') {
      setPlaceholder('Type a date in YYYY-MM-DD format (e.g. 2021-10-13)')
    } else if (find === 'filename') {
      setPlaceholder('Type file name')
    } else if (find === 'file_type') {
      setPlaceholder('Type file type (image/text/application)')
    } else if (find === 'file_section') {
      setPlaceholder('Files, Videos, Recordings, Emails...')
    } else if (find === 'text__contains') {
      setPlaceholder('File content name')
    }
  }, [find])

  useEffect(() => {
    dispatch(getSearch(search, find))
  
  }, [search, find])

  console.log('object', optionsModalVisible)
  const onPresss = (o, buttonIndex) => {
    setSearch({ 'findName': o })
    setModalVisible(false)

  }
  useEffect(() => {
    if (!visible) {
      setFind('filename')
      setModalVisible(false)
    }
  }, [visible])

  const onFind = (x) => {
    setFind(x)
    if (x === 'file_section') {
      setModalVisible(true)
    }

  }
  return (
    <SafeAreaView style={{ flex: 1 }}>
      <ScrollView>
        <ActionSheet
          options={options}
          onPress={onPresss}
          isModalVisible={optionsModalVisible}
          setModalVisible={setModalVisible}
        
        />
        <View style={styles.body}>
          {
            isWeb() 
              ? <>
                <WebHeader navigation={navigation}/>
                <Text style={styles.title}> Dashboard </Text> 
              </> : null
          }
          <InputSearch 
            placeholder={placehold}
            search={require('../assets/search.png')}
            filter={visible ? require('../assets/filter2.png') : require('../assets/filter.png')}
            onPress={() => setVisible(!visible)}
            visible={visible}
            onChangeText={(v) => onSearch('findName', v)} 
            value={search.findName} 
          />
          {visible 
            ? <>
              <View style={styles.searchContainer}>
                <TouchableOpacity onPress={() => onFind('filename')} style={[styles.filterBar, { backgroundColor: find === 'filename' ? '#e8e8e8' : null }]}><Text>File name</Text>
                  <Image style={styles.icon} resizeMode="contain" source={find === 'filename' ? require('../assets/EllipseX.png') : require('../assets/Ellipse.png')}/>    
                </TouchableOpacity>
                <TouchableOpacity onPress={() => onFind('updated')} style={[styles.filterBar, { backgroundColor: find === 'updated' ? '#e8e8e8' : null }]}><Text>Date</Text>
                  <Image style={styles.icon} resizeMode="contain" source={find === 'updated' ? require('../assets/EllipseX.png') : require('../assets/Ellipse.png')}/>    
                </TouchableOpacity>
                <TouchableOpacity onPress={() => onFind('file_type')} style={[styles.filterBar, { backgroundColor: find === 'file_type' ? '#e8e8e8' : null }]}><Text>Type</Text>
                  <Image style={styles.icon} resizeMode="contain" source={find === 'file_type' ? require('../assets/EllipseX.png') : require('../assets/Ellipse.png')}/>    
                </TouchableOpacity>
                <TouchableOpacity onPress={() => onFind('file_section')} style={[styles.filterBar, { backgroundColor: find === 'file_section' ? '#e8e8e8' : null }]}><Text>Section</Text>
                  <Image style={styles.icon} resizeMode="contain" source={find === 'file_section' ? require('../assets/EllipseX.png') : require('../assets/Ellipse.png')}/>    
                </TouchableOpacity>
                <TouchableOpacity onPress={() => setFind('text__contains')} style={[styles.filterBar, { backgroundColor: find === 'text__contains' ? '#e8e8e8' : null }]}><Text>Text contains</Text>
                  <Image style={styles.icon} resizeMode="contain" source={find === 'text__contains' ? require('../assets/EllipseX.png') : require('../assets/Ellipse.png')}/>    
                </TouchableOpacity>

                <TouchableOpacity onPress={() => setFind('filename__contains')} style={[styles.filterBar, { backgroundColor: find === 'filename__contains' ? '#e8e8e8' : null }]}><Text>File name contains</Text>
                  <Image style={styles.icon} resizeMode="contain" source={find === 'filename__contains' ? require('../assets/EllipseX.png') : require('../assets/Ellipse.png')}/>    
                </TouchableOpacity>
              </View>
            
              {   
                list?.length 
                  ? list.map((item) => <>
                    <View key={item.id} style={styles.searchContainer}>
                      <View key={item.id} style={styles.filterBar}>
                        <View key={item.id}>
                          <TouchableOpacity
                            onPress={ () => {
                              Linking.openURL(item.file) 
                            }}>
                            <Text style={{ fontWeight: 'bold' }}>{item.filename}</Text>
                          </TouchableOpacity>
                          <Text style={styles.type}>{item.mime_type}</Text>
                        </View>
                        {
                          show === item.id || modal === item.id ? null
                            : <TouchableOpacity onPress={() => onModal(item.id)}>
                              <Image resizeMode="contain" style={styles.icon} source={require('../assets/trash.png')} />
                              {/* <Text style={{ color: colors.blue,
                          fontWeight: 'bold' }}>Delet</Text> */}
                            </TouchableOpacity>
                        }
                      </View>
                      {
                        modal === item.id 
                          ? <>
                            <View style={{ flexDirection: 'row',
                              justifyContent: 'center' }}>
                              <TouchableOpacity style={styles.button} onPress={() => onDelete(item.id)}>
                                <Text style={styles.buttonTextDelete}>Yes delete</Text>
                              </TouchableOpacity>
                              <TouchableOpacity style={styles.button} onPress={() => setModal(0)}>
                                <Text style={styles.buttonText}>Cancel</Text>
                              </TouchableOpacity>
                            </View>
                          </>
                          : null
                      }
                    </View>
                  </>)
                  : <Text style={{ marginVertical: 50 }}>This section is empty, please search files.</Text>
              }

            </>
            : <>
              {
                isWeb()
                  ? <>
                    <View style={styles.feature}>
                      <FeatureCard onPress={() => navigation.navigate('MessageCenter')} title="Chats" image={require('../assets/device.png')}/>
                      <FeatureCard onPress={() => navigation.navigate('FilesWeb', { item: 'files' })} title="Files" image={require('../assets/archive.png')}/>
                      <FeatureCard onPress={() => navigation.navigate('Files', { item: 'videos' })} title="Videos" image={require('../assets/video-play.png')}/>
                      <FeatureCard onPress={() => navigation.navigate('Files', { item: 'recordings' })} title="Recordings" image={require('../assets/video.png')}/>
                    </View>
                    <View style={styles.feature}>
                      <FeatureCard onPress={() => navigation.navigate('Emails', { item: 'emails' })} title="Emails" image={require('../assets/sms.png')}/>
                      <FeatureCard onPress={() => navigation.navigate('Files', { item: 'calls' })} title="Call Logs & Recordings" image={require('../assets/call.png')}/>
                      <FeatureCard onPress={() => navigation.navigate('Files', { item: 'sms' })} title="SMS" image={require('../assets/messages.png')}/>
                      <FeatureCard 
                        onPress={() => setCollab(!collab)} 
                        title="Add Collaborator" image={require('../assets/profile.png')}/>
                    </View>
                    <View style={styles.feature}> 
                      <FeatureCard 
                        onPress={() => navigation.navigate('SharedData')}
                        title="Shared Data" image={require('../assets/share.png')}/> 
                    </View>
                  </>
                  : <>
                    <View style={styles.feature}>
                      <FeatureCard onPress={() => navigation.navigate('MessageCenter')} title="Chats" image={require('../assets/device.png')}/>
                      <FeatureCard onPress={() => navigation.navigate('Files', { item: 'files' })} title="Files" image={require('../assets/archive.png')}/>
                    </View>
                    <View style={styles.feature}>
                      <FeatureCard onPress={() => navigation.navigate('Files', { item: 'videos' })} title="Videos" image={require('../assets/video-play.png')}/>
                      <FeatureCard onPress={() => navigation.navigate('Files', { item: 'emails' })} title="Emails" image={require('../assets/sms.png')}/>
                    </View>
                    <View style={styles.feature}>
                      <FeatureCard onPress={() => navigation.navigate('Files', { item: 'calls' })} title="Call Logs & Recordings" image={require('../assets/call.png')}/>
                      <FeatureCard onPress={() => navigation.navigate('Files', { item: 'sms' })} title="SMS" image={require('../assets/messages.png')}/>
                    </View>
                    <View style={styles.feature}>
                      <FeatureCard onPress={() => navigation.navigate('Files', { item: 'recordings' })} title="Recordings" image={require('../assets/video.png')}/>
                      {/* <FeatureCard title="Share" image={require('../assets/share.png')}/> */}
                      <FeatureCard 
                        onPress={() => setCollab(!collab)} 
                        title="Add Collaborator" image={require('../assets/profile.png')}/>
                    </View>
                  </>
              }
              {/* <View style={styles.feature}> 
                <FeatureCard 
                  onPress={() => navigation.navigate('Membership')}
                  title="Delete" image={require('../assets/trash.png')}/> 
              </View> */}
              <UploadWeb />  
            </>
          }
        </View>

        <Dialog
          visible={collab}
          onTouchOutside={() => setCollab(false)}
        >
          <DialogContent style={{ backgroundColor: colors.backColor,
            marginTop: isWeb() ? height : null }}>
            <Text style={{ textAlign: 'center',
              fontSize: 16,
              fontWeight: 'bold',
              padding: 15 }}>Add Collaborator </Text>
            <Input onChangeText={(v) => onChange('customer_email', v)} value={values.customer_email} title="Email" placeholder="Type email" />
            <Input onChangeText={(v) => onChange('title', v)} value={values.title} title="Title" placeholder="Type title" />
            <Input onChangeText={(v) => onChange('message', v)} value={values.message} title="Message" placeholder="Type Message" />
            <Button onPress={() => onInvite()} name="Invite +" backgroundColor={colors.blue} color={colors.white} />
            {
              isWeb() 
                ? <Button onPress={() => setCollab(false)} name="Cancel" backgroundColor={colors.cancelColor} color={colors.blue} />
                : null
            }
          </DialogContent>
        </Dialog>
      </ScrollView>
    </SafeAreaView>
  ) 
}
const styles = StyleSheet.create({
  buttonTextDelete: {
    color: 'red',
    fontWeight: 'bold'
  },
  title: {
    color: colors.blue,
    fontWeight: 'bold',
    fontSize: 18,
    padding: 10,
    marginTop: 50
  },
  buttonText: {
    color: colors.blue,
    fontWeight: 'bold'
  },
  button: {
    paddingHorizontal: 25,
    paddingVertical: 15
  },
  detailsCont: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  details: {
    padding: 20
  },
  type: {
    fontSize: 12,
    color: colors.textColor,
    fontWeight: '400'
  },
  text: {
    fontSize: 20,
    margin: 22,
    color: colors.white,
    letterSpacing: 12,
    fontWeight: 'bold'
  },

  popupText: {
    fontSize: 16,
    color: colors.black
  },
  popupContainer: {
    padding: 25,
    borderBottomWidth: 1,
    borderColor: colors.border
  },
  icon: {
    width: 20,
    height: 20
  },    
  filterBar: {
    padding: 10,
    paddingHorizontal: 20,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  searchContainer: {
    marginTop: 20,
    backgroundColor: colors.white,
    width: isWeb() ? 500 : '93%',
  },
  feature: {
    flexDirection: 'row'
  },
  body: {
    padding: 15,
    alignItems: 'center',
    width: isWeb() ? '100%' : null,
    height: isWeb() ? height : null
    // backgroundColor: isWeb() ? 'white' : null,
    // marginHorizontal: '25%'
  }
})
export default HomeDashboard;