import React from 'react'
import { View, StyleSheet, Text, SafeAreaView, Dimensions } from 'react-native'
import { colors } from '../utils/colors'
import Logo from '../components/Logo'
import Button from '../components/Button'
import { isWeb } from '../utils/isweb'
const { width } = Dimensions.get('window');
const { height } = Dimensions.get('window');
const WelcomeScreen = ({ navigation }) => (
  <SafeAreaView style={styles.body}>
    <View style={styles.container}>
      <Logo 
        imageLogo={require('../assets/logo.png')}
        color={colors.white}
      />
      <Text style={styles.content}>
      Saving You Time, Money, & Stress
      </Text>
    </View>
    <View style={styles.buttonContainer}>
      <Button onPress={() => navigation.navigate('Login', { item: 'lawyer' })} name="I’m a Lawyer" backgroundColor={colors.white} color={colors.blue}/>
      <Button onPress={() => navigation.navigate('Login', { item: 'customer' })} name="I’m a Customer" backgroundColor={colors.white} color={colors.blue}/>
    </View>
  </SafeAreaView>
)
const styles = StyleSheet.create({
  buttonContainer: {
    padding: 25,
    width: isWeb() ? 500 : null,
  },
  content: {
    marginTop: 30,
    fontSize: 16,
    color: colors.white,
    paddingHorizontal: 40,
    textAlign: 'center'
  },
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 40
  },
  body: {
    flexDirection: 'column',
    justifyContent: isWeb() ? 'center' : 'space-between',
    alignItems: isWeb() ? 'center' : null,
    backgroundColor: colors.blue,
    height: isWeb() ? height : null,
    width: isWeb() ? width : null,
    flex: isWeb() ? null : 1
  }
})
export default WelcomeScreen;