export const AUTH_SIGNUP_REQUEST = 'AUTH/SIGNUP/REQUEST';
export const AUTH_SIGNUP_SUCCESS = 'AUTH/SIGNUP/SUCCESS';
export const AUTH_SIGNUP_ERROR = 'AUTH/SIGNUP/ERROR';

export const AUTH_LOGIN_REQUEST = 'AUTH/LOGIN/REQUEST';
export const AUTH_LOGIN_SUCCESS = 'AUTH/LOGIN/SUCCESS';
export const AUTH_LOGIN_ERROR = 'AUTH/LOGIN/ERROR';

export const AUTH_FACEBOOK_LOGIN_REQUEST = 'AUTH/FACEBOOK_LOGIN/REQUEST';
export const AUTH_FACEBOOK_LOGIN_SUCCESS = 'AUTH/FACEBOOK_LOGIN/SUCCESS';
export const AUTH_FACEBOOK_LOGIN_ERROR = 'AUTH/FACEBOOK_LOGIN/ERROR';

export const AUTH_INSTAGRAM_LOGIN_REQUEST = 'AUTH/INSTAGRAM_LOGIN/REQUEST';
export const AUTH_INSTAGRAM_LOGIN_SUCCESS = 'AUTH/INSTAGRAM_LOGIN/SUCCESS';
export const AUTH_INSTAGRAM_LOGIN_ERROR = 'AUTH/INSTAGRAM_LOGIN/ERROR';

export const AUTH_GOOGLE_LOGIN_REQUEST = 'AUTH/GOOGLE_LOGIN/REQUEST';
export const AUTH_GOOGLE_LOGIN_SUCCESS = 'AUTH/GOOGLE_LOGIN/SUCCESS';
export const AUTH_GOOGLE_LOGIN_ERROR = 'AUTH/GOOGLE_LOGIN/ERROR';

// does not follow the patern REQUERST/SUCCESS/ERROR since it is syncronus action
export const AUTH_LOGOUT = 'AUTH/LOGOUT';

export const AUTH_PASSWORD_RECOVER_REQUEST = 'AUTH/PASSWORD_RECOVER/REQUEST';
export const AUTH_PASSWORD_RECOVER_SUCCESS = 'AUTH/PASSWORD_RECOVER/SUCCESS';
export const AUTH_PASSWORD_RECOVER_ERROR = 'AUTH/PASSWORD_RECOVER/ERROR';

export const AUTH_PASSWORD_RESET_REQUEST = 'AUTH/PASSWORD_RESET/REQUEST';
export const AUTH_PASSWORD_RESET_SUCCESS = 'AUTH/PASSWORD_RESET/SUCCESS';
export const AUTH_PASSWORD_RESET_ERROR = 'AUTH/PASSWORD_RESET/ERROR';

export const AUTH_USER = 'AUTH/USER';