import React, { useState } from 'react';
import { Text, TouchableOpacity, View, Image } from 'react-native';

import { withAppContext } from '../context';
import { colors } from '../utils/colors';

const User = (props) => {
  const { user, selected, selectable, onSelect } = props;
  const [select, setSelect] = useState(selected);
  const onPress = () => {
    if (selectable) {
      setSelect(!select);
      onSelect(user);
    }
  };
  return (
    <TouchableOpacity activeOpacity={0.75} style={style.container} onPress={onPress}>
      <View style={style.profileImageContainer}>
        <Image source={{ uri: user.profileUrl }} style={style.profileImage} />
        {selected ? <Image source={require('../assets/checked.png')} style={style.check} /> : null}
      </View>
      <Text style={style.nickname}>{user.nickname || '(Unnamed)'}</Text>
    </TouchableOpacity>
  );
};

const style = {
  container: {
    flexDirection: 'row',
    paddingHorizontal: 20,
    paddingTop: 15,
    paddingBottom: 8,
    alignItems: 'center',
  },
  profileImageContainer: {
    position: 'relative',
    width: 24,
    height: 24,
    marginRight: 12,
    justifyContent: 'center'
  },
  profileImage: {
    position: 'absolute',
    backgroundColor: '#ddd',
    width: 24,
    height: 24,
    borderWidth: 0,
    borderRadius: 20
  },
  check: {
    position: 'absolute',
    opacity: 0.8,
    width: 24,
    height: 24,
    borderWidth: 0,
    borderRadius: 20,
    backgroundColor: colors.blue
  },
  nickname: {
    fontSize: 17,
    color: colors.black
  }
};

export default withAppContext(User);