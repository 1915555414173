import React, { useEffect, useReducer, useState } from 'react';
import {
  Text,
  SafeAreaView,
  View,
  FlatList,
  RefreshControl,
  AppState
} from 'react-native';
import { StackActions } from '@react-navigation/native';

import { withAppContext } from '../context';
import { inviteReducer } from '../redux/chat/invite';
import User from '../components/User';
import Button from '../components/Button';
import Header from '../components/Header';
import { colors } from '../utils/colors';
import { isWeb } from '../utils/isweb';

const Invite = (props) => {
  const { route, navigation, sendbird } = props;
  const { currentUser, channel } = route.params;

  const [query, setQuery] = useState(null);
  const [state, dispatch] = useReducer(inviteReducer, {
    channel,
    users: [],
    userMap: {},
    selectedUsers: [],
    loading: false,
    error: ''
  });

  // on state change
  useEffect(() => {
    sendbird.addConnectionHandler('invite', connectionHandler);
    AppState.addEventListener('change', handleStateChange);

    if (!sendbird.currentUser) {
      sendbird.connect(currentUser.userId, (err) => {
        if (!err) {
          refresh();
        } else {
          dispatch({
            type: 'error',
            payload: {
              error: 'Connection failed. Please check the network status.'
            }
          });
        }
      });
    } else {
      refresh();
    }

    return () => {
      dispatch({ type: 'end-loading' });
      sendbird.removeConnectionHandler('invite');
      AppState.removeEventListener('change', handleStateChange);
    };
  }, []);

  useEffect(() => {
    if (query) {
      next(); 
    }
  }, [query]);

  // / on connection event
  const connectionHandler = new sendbird.ConnectionHandler();
  connectionHandler.onReconnectStarted = () => {
    dispatch({
      type: 'error',
      payload: {
        error: 'Connecting..'
      }
    });
  };
  connectionHandler.onReconnectSucceeded = () => {
    dispatch({
      type: 'error',
      payload: {
        error: ''
      }
    });
    refresh();
  };
  connectionHandler.onReconnectFailed = () => {
    dispatch({
      type: 'error',
      payload: {
        error: 'Connection failed. Please check the network status.'
      }
    });
  };

  const handleStateChange = (newState) => {
    if (newState === 'active') {
      sendbird.setForegroundState();
    } else {
      sendbird.setBackgroundState();
    }
  };
  const invite = () => {
    if (state.selectedUsers.length > 0) {
      dispatch({ type: 'start-loading' });
      if (!channel) {
        const params = new sendbird.GroupChannelParams();
        params.addUsers(state.selectedUsers);
        sendbird.GroupChannel.createChannel(params, (err, channel) => {
          if (!err) {
            dispatch({ type: 'end-loading' });
            navigation.dispatch(StackActions.replace('Chat', {
              currentUser,
              channel
            }));
          } else {
            dispatch({
              type: 'error',
              payload: {
                error: err.message
              }
            });
          }
        });
      } else {
        channel.invite(state.selectedUsers, (err) => {
          if (!err) {
            dispatch({ type: 'end-loading' });
            navigation.goBack();
          } else {
            dispatch({
              type: 'error',
              payload: {
                error: err.message
              }
            });
          }
        });
      }
    } else {
      dispatch({
        type: 'error',
        payload: {
          error: 'Select at least 1 user to invite.'
        }
      });
    }
  };
  const refresh = () => {
    setQuery(sendbird.createApplicationUserListQuery());
    dispatch({ type: 'refresh' });
  };
  const next = () => {
    if (query.hasNext) {
      dispatch({ type: 'start-loading' });
      query.limit = 50;
      query.next((err, fetchedUsers) => {
        dispatch({ type: 'end-loading' });
        if (!err) {
          dispatch({
            type: 'fetch-users',
            payload: { users: fetchedUsers }
          });
        } else {
          dispatch({
            type: 'error',
            payload: {
              error: 'Failed to get the users.'
            }
          });
        }
      });
    }
  };
  const onSelect = (user) => {
    if (!state.selectedUsers.includes(user)) {
      dispatch({ type: 'select-user',
        payload: { user } });
    } else {
      dispatch({ type: 'unselect-user',
        payload: { user } });
    }
  };

  return (
    <SafeAreaView style={style.container}>
      <View
        style={{
          alignItems: isWeb() ? 'center' : null
        }}
      >
        <View
          style={{
            width: isWeb() ? 500 : 0,
          }}
        >
          <View style={style.body}>
            <Header title="Invite" onPress={() => navigation.goBack()}/>
          </View>

          <Button disabled={!state.selectedUsers?.length} backgroundColor={colors.blue} color={colors.white} name="Invite" onPress={invite} />

          <FlatList
            data={state.users}
            renderItem={({ item }) => (
              <User
                key={item.userId}
                user={item}
                selected={state.selectedUsers.includes(item)}
                selectable={true}
                onSelect={onSelect}
              />
            )}
            keyExtractor={(item) => item.userId}
            refreshControl={
              <RefreshControl refreshing={state.loading} colors={['#0069d8']} tintColor={'#0069d8'} onRefresh={refresh} />
            }
            contentContainerStyle={{ flexGrow: 1 }}
            ListHeaderComponent={
              state.error && (
                <View style={style.errorContainer}>
                  <Text style={style.error}>{state.error}</Text>
                </View>
              )
            }
            onEndReached={() => next()}
            onEndReachedThreshold={0.5}
          />     
        </View>
      </View>
    </SafeAreaView>
  );
};

const style = {
  body: {
    padding: 20,
    paddingVertical: isWeb() ? 35 : 0
  },
  container: {
    flex: 1,
  },
  inviteButton: {
    marginRight: 12
  },
  errorContainer: {
    backgroundColor: '#333',
    opacity: 0.8,
    padding: 10
  },
  error: {
    color: '#fff'
  },
};

export default withAppContext(Invite);