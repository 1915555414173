import React from 'react'
import { View, StyleSheet, Image, Text, TouchableOpacity, Dimensions } from 'react-native'
import { isWeb } from '../utils/isweb'

const { width } = Dimensions.get('screen')
console.log(width)
const Header = ({ title, back, onPress, width, onPressPlus, plus }) => (
  <View style={[styles.header, { width: width ? width : '90%' }]}>
    {
      back 
        ? <Text style={styles.headerTitle} />
        : <TouchableOpacity onPress={onPress}>
          <Image
            style={styles.icon}
            resizeMode="contain"
            source={require('../assets/back.png')}
          />    
        </TouchableOpacity> 
    } 
    <Text style={styles.headerTitle}>
      {title}
    </Text>  
    {
      plus 
        ? <TouchableOpacity onPress={onPressPlus}>
          <Image
            style={styles.icon}
            resizeMode="contain"
            source={plus}
          />    
        </TouchableOpacity> 
        : <Text style={styles.headerTitle}/>  
    }
  </View>
)
const styles = StyleSheet.create({
  headerTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    minWidth: 30
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: isWeb() ? 470 : width - 32,
  },    
  icon: {
    width: 20,
    height: 20
  }
})
export default Header;