import React from 'react'
import { View, StyleSheet } from 'react-native'
import Input from '../components/Input'
import { colors } from '../utils/colors'
import Button from '../components/Button'
import Header from '../components/Header'
import { isWeb } from '../utils/isweb'

const SuggestImprovements = ({ navigation }) => (
  <View style={styles.body}>
    <View style={styles.container}>
      <Header onPress={() => navigation.goBack('')} title="Suggest Improvements" />
      <Input placeholder="Your email id" textArea={true}/>
      <Button name="Submit" backgroundColor={colors.blue} color={colors.white}/>
      <Button onPress={() => navigation.goBack('')} name="Cancel" backgroundColor={colors.cancelColor} color={colors.blue}/>
 
    </View>
  </View>
)
const styles = StyleSheet.create({
  headerTitle: {
    fontSize: 16,
    fontWeight: 'bold'
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },    
  icon: {
    width: 10,
    height: 14
  }, 
  body: {
    padding: 15,
    backgroundColor: colors.whiteBackground,
    flex: 1,
    alignItems: isWeb() ? 'center' : null
  },
  container: {
    width: isWeb() ? 500 : null
  }
})
export default SuggestImprovements;