import React, { useEffect } from 'react';
import { 
  StyleSheet,
  View,
  ScrollView,
} from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../components/Header';
import MemberShipCard from '../components/MemberShipCard';
import { creatSubscription, getSubscripeFees } from '../redux/app/actions';
import { isWeb } from '../utils/isweb';

const Membership = ({ navigation }) => {
  const dispatch = useDispatch()
  const fees = useSelector((state) => state.App.subscripeFees);
  const addCardList = useSelector((state) => state.App.addCardList);
  
  useEffect(() => {
    dispatch(getSubscripeFees())
  }, []);

  const updatePlan = (subscription_type, payment_source) => {
    dispatch(creatSubscription(subscription_type, payment_source, navigation))
  }
  return (
    <ScrollView contentContainerStyle={styles.scroll}>
      <View style={styles.body}>
        <Header title="Membership" onPress={() => navigation.goBack()}/>
        {
          fees.map((post) => <>
            <MemberShipCard
              key={post.id}
              updatePlan={() => updatePlan(post.subscription_type, addCardList.id)}
              price={`${post.subscription_type} / $ ${post.amount}`} 
              content={'This is the description from the monthly membership plan, it can be one line or two lines.'}
              check1={'Unlimited files for you'}
              check2={'Unlimited downloads'}
              check3={'Multi-platform dashboard'}
              image={require('../assets/calendarSchedule.png')}
            />
          </>)
        }
      </View>
    </ScrollView>
  ) 
}
const styles = StyleSheet.create({
  body: {
    padding: 25,
    backgroundColor: '#E5E5E5',

    width: isWeb() ? 500 : null
  },
  scroll: {
    backgroundColor: '#E5E5E5',
    flexGrow: 1,
    alignItems: isWeb() ? 'center' : null
  }
});
export default Membership;