import React, { useEffect } from 'react'
import { View, StyleSheet, Dimensions } from 'react-native'
import { colors } from '../utils/colors'
import Logo from '../components/Logo'
import { useDispatch, useSelector } from 'react-redux'
import { getApplications } from '../redux/app/actions'
const { height } = Dimensions.get('window');

const SplashScreen = ({ navigation }) => {
  const profile = useSelector((state) => state.App.profile);
  const dispatch = useDispatch();

  useEffect(() => {
    if (profile.id) {
      dispatch(getApplications());
      if (profile?.active_subscription?.is_active === true) {
        navigation.navigate('HomeDashboard')
      } else if (profile.user_type === 'laywer' || profile.user_type === 'lawyer') {
        // navigation.navigate('AddNewCard')
        navigation.navigate('HomeDashboard')
      } else {
        navigation.navigate('HomeDashboard')
      }
    }
  }, [profile])

  return (
    <View style={styles.body}>
      <Logo 
        imageLogo={require('../assets/logo.png')}
        color={colors.white}
      />
    </View>
  )
}
const styles = StyleSheet.create({
  text: {
    fontSize: 20,
    margin: 22,
    color: colors.white,
    letterSpacing: 12,
    fontWeight: 'bold'
  },
  body: {
    backgroundColor: colors.blue,
    justifyContent: 'center',
    alignItems: 'center',
    height
  }
})
export default SplashScreen;