export const colors = {
  yellow: '#F6E508',  
  white: '#FFFFFF',
  black: '#000000',
  blue: '#0069d8',
  whiteBackground: '#f8f9fb',
  cancelColor: '#ddeaf8',
  textColor: '#ADACAC',
  border: '#CCCCCC',
  backColor: '#E5E5E5'
  
}